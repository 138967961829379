<template>
  <section class="car-auction-section pos-rel oh">
    <div class="container">
      <div class="row" v-if="getLeiloesDestaqueLoading || getLeiloesFechadoLoading">
        <leilao-card-loader classeRaiz="col-12 col-sm-6 col-md-4 col-lg-3" />
        <leilao-card-loader classeRaiz="col-sm-6 col-md-4 col-lg-3 d-none d-sm-block d-md-block d-lg-block" />
        <leilao-card-loader classeRaiz="col-sm-6 col-md-4 col-lg-3 d-none d-sm-none d-md-block d-lg-block" />
        <leilao-card-loader classeRaiz="col-lg-3 d-none d-sm-none d-md-none d-lg-block" />
      </div>
      <div class="row justify-content-center" v-if="!getLeiloesDestaqueLoading && !getLeiloesFechadoLoading">
        <template v-for="leilao in getLeiloes">
          <leilao-card
            v-if="!leilao.parceiro"
            :key="leilao.id"
            classeRaiz="col-10 col-sm-10 col-md-6 col-lg-3"
            :leilao="getLeilaoDestaque(leilao.id)"
          />
          <leilao-parceiro-card
            v-if="leilao.parceiro"
            :key="leilao.id"
            classeRaiz="col-10 col-sm-10 col-md-6 col-lg-3"
            :leilao="getLeilaoParceiro(leilao.id)"
          />
        </template>

        <leilao-card v-for="leilao in getLeiloesFechado" :key="leilao.id" classeRaiz="col-10 col-sm-10 col-md-6 col-lg-3" :leilao="leilao" />
      </div>

      <div class="center d-flex">
        <router-link :to="'/leiloes'">
          <h4 class="title mb-3 mt-5 verTodos">VER TODOS OS LEILÕES</h4>
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import LeilaoCard from '@/components/LeilaoCard.vue';
import LeilaoParceiroCard from '@/components/LeilaoParceiroCard.vue';
import LeilaoCardLoader from '@/components/LeilaoCardLoader.vue';
// import { Carousel, Slide } from 'vue-carousel';
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  components: {
    LeilaoCardLoader,
    LeilaoCard,
    LeilaoParceiroCard,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    ...mapGetters(['getLeiloesParceiro', 'getLeiloesDestaque', 'getLeiloesFechado', 'getLeiloesDestaqueLoading', 'getLeiloesFechadoLoading']),
    isMobile() {
      return this.windowWidth < 576;
    },
    getLeiloes() {
      if (this.getLeiloesDestaque.data && this.getLeiloesParceiro.data) {
        let leiloes = this.getLeiloesDestaque.data.map((x) => ({ id: x.id, data: x.dataLeilao, parceiro: false }));

        this.getLeiloesParceiro.data.forEach((x) => {
          let dataLeilao = x.pracas[0];
          if (x.pracas.length > 1 && new Date() > new Date(dataLeilao)) {
            dataLeilao = x.pracas[1];
          }
          leiloes.push({ id: x.leilaoParceiroId, data: dataLeilao, parceiro: true });
        });

        return leiloes.sort((a, b) => new Date(a.data) - new Date(b.data));
      }

      return [];
    },
  },
  methods: {
    ...mapActions(['setLeiloesParceiro', 'setLeiloesDestaque', 'setLeiloesPorStatus']),
    ...mapMutations(['SET_LEILOES_FECHADO']),
    getLeilaoDestaque(id) {
      return this.getLeiloesDestaque.data.find((x) => x.id == id);
    },
    getLeilaoParceiro(id) {
      return this.getLeiloesParceiro.data.find((x) => x.leilaoParceiroId == id);
    },
  },
  async mounted() {
    await this.setLeiloesDestaque();
    await this.setLeiloesParceiro();

    if (this.getLeiloes && this.getLeiloes.length < 12) {
      const pageSize = 12 - this.getLeiloes.length;
      this.SET_LEILOES_FECHADO();
      await this.setLeiloesPorStatus({
        pageSize: pageSize,
        pageNumber: 1,
        status: 'fechado',
      });
    }
  },
};
</script>

<style>
.carousel-leiloes .VueCarousel-wrapper {
  padding: 10px 0;
}

.VueCarousel-navigation-button {
  width: 50px;
}

.VueCarousel-slide {
  display: flex;
}

.verTodos {
  color: #171d1c;
  cursor: pointer;
  font-size: 1rem;
}

.buttons-filter {
  border-radius: 13px;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0b184a;
  color: #fff;
}
@media (max-width: 1199px) {
  .section-header-4 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 0 !important;
  }
}
</style>
