<template>
  <!-- Modal -->
  <div>
    <div
      class="modal fade"
      id="modalCondicoes"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalCondicoesTitle"
      aria-hidden="true"
    >
      <a
        id="mostrarModalVerifica"
        href="javascript:void(0)"
        data-target="#modalVerifica"
        data-toggle="modal"
      >
      </a>
      <div
        class="modal-dialog modal-xl modal-dialog-scrollable"
        role="document"
      >
        <div class="modal-content">
          <button
            v-show="false"
            id="fechaModalCondicoes"
            data-dismiss="modal"
          ></button>
          <div class="modal-header">
            <h5 class="modal-title">Condições de venda</h5>
            <a
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </a>
          </div>
          <div class="modal-body">
            <div v-html="getLeilao.termoCondicaoVenda"></div>
          </div>

          <div v-if="!getAuth" class="mt-3 mb-2 text-center font-weight-bold">
            <p>Você precisa estar logado para participar</p>
          </div>
          <div v-else>
            <div class="mt-3 mb-2 termos">
              <input
                type="checkbox"
                name="terms"
                id="check"
                v-model="aceito"
              /><label for="check"
                >Eu <b>{{ getUsuarioInfo.nomeCompleto }}</b> declaro que li e
                aceito as condições de venda do leilão
                <b>{{ getLeilao.nome }}</b>
              </label>
            </div>
            <div class="text-danger pl-3" v-if="error">
              Leia e Aceite as condições de venda para avançar
            </div>
          </div>
          <div class="modal-footer">
            <a v-show="false" data-dismiss="modal"> </a>
            <a
              v-if="getAuth"
              href="javascript:void(0)"
              class="custom-button"
              @click="avancar"
            >
              Avançar
            </a>
            <div v-else>
              <a
                href="javascript:void(0)"
                class="custom-button mr-2"
                @click="goToLogin"
              >
                Fazer login
              </a>
              <a
                style="color: #062745"
                href="/cadastro"
                class="custom-button transparent"
              >
                Cadastre-se
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      aceito: false,
      error: false,
    };
  },
  computed: {
    ...mapGetters(['getAuth', 'getLeilao', 'getLote', 'getUsuarioInfo']),
  },
  methods: {
    avancar() {
      this.error = false;
      if (!this.aceito) {
        this.error = true;
        return;
      }
      document.getElementById('fechaModalCondicoes').click();
      this.$nextTick(() => {
        document.getElementById('mostrarModalVerifica').click();
      });
    },
    goToLogin() {
      document.getElementById('fechaModalCondicoes').click();
      this.$router.push({
        path: '/login',
        query: { returnUrl: this.$route.path },
      });
    },
  },
};
</script>

<style scoped>
.termos {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 16px;
  line-height: 20px;
}
input[type='checkbox'] {
  height: 20px;
  width: 25px;
  margin-right: 10px;
}
.termos label {
  margin-bottom: -10px;
  font-size: 14px;
}

.modal-body {
  padding: 20px;
}
</style>
