export default {
  methods: {
    showAlert(val) {
      return this.$swal.fire({
        position: "center",
        icon: val.type,
        title: val.title,
        text: val.message,
        showConfirmButton: val.showConfirmButton,
        confirmButtonColor: "#065817",
        timer: val.timer && 4000,
        timerProgressBar: val.timer ? true : false,
      });
    },
    showConfirm(val) {
      return this.$swal.fire({
        icon: val.type,
        title: val.title,
        text: val.message,

        confirmButtonColor: "#065817",
        confirmButtonText: "Sim",
        showCancelButton: true,
        cancelButtonColor: "#E53935",
        cancelButtonText: "Não",
      });
    },
    showToast(val) {
      this.$swal.fire({
        position: "top-end",
        icon: val.type || "success",
        text: val.title,
        showConfirmButton: false,
        timer: 5000,
        toast: true,
      });
    },
  },
};
