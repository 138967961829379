export function getVisitorId() {
  const VISITOR_ID_KEY = 'visitorId';
  let visitorId = localStorage.getItem(VISITOR_ID_KEY);

  if (!visitorId) {
    visitorId = 'visitor-' + Math.random().toString(36).substr(2, 9);
    localStorage.setItem(VISITOR_ID_KEY, visitorId);
  }

  return visitorId;
}
