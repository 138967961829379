import http from '../../service/http/http';

const state = {
  agLoteId: null,
  agLote: null,
  agData: null,
  agHora: null,
  agAgendar: false,
  agTela: 'Lotes',
  agAgendamentoId: null,
};

const mutations = {
  setAgLoteId: (state, value) => (value ? (state.agLoteId = value) : (state.agLoteId = null)),
  setAgLote: (state, value) => (value ? (state.agLote = value) : (state.agLote = null)),
  setAgData: (state, value) => (value ? (state.agData = value) : (state.agData = null)),
  setAgHora: (state, value) => (value ? (state.agHora = value) : (state.agHora = null)),
  setAgAgendar: (state, value) => (value ? (state.agAgendar = value) : (state.agAgendar = false)),
  setAgTela: (state, value) => (value ? (state.agTela = value) : (state.agTela = 'Lotes')),
  setAgAgendamentoId: (state, value) => (value ? (state.agAgendamentoId = value) : (state.agAgendamentoId = null)),
};

const getters = {
  getAgLoteId: (state) => state.agLoteId,
  getAgLote: (state) => state.agLote,
  getAgData: (state) => state.agData,
  getAgHora: (state) => state.agHora,
  getAgAgendar: (state) => state.agAgendar,
  getAgTela: (state) => state.agTela,
  getAgAgendamentoId: (state) => state.agAgendamentoId,
};

const actions = {
  async agendar(_, value) {
    return new Promise((resolve, reject) => {
      http
        .post('Agendamento', value)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async agendamentoPorLote(_, value) {
    return new Promise((resolve, reject) => {
      http
        .get(`Agendamento/Lote/${value}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async agendamentoPorId(_, agendamentoId) {
    return new Promise((resolve, reject) => {
      http
        .get(`Agendamento/${agendamentoId}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async agendamentoConfig(_, value) {
    return new Promise((resolve, reject) => {
      http
        .get(`Agendamento/Configuracao/Local/${value}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async agendamentoBloqueio(_, value) {
    return new Promise((resolve, reject) => {
      http
        .get(`Agendamento/Bloqueio/Configuracao/${value}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async agendaDisponivel(_, loteId) {
    return new Promise((resolve, reject) => {
      http
        .get(`Agendamento/Disponivel/${loteId}`)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  async agendamentoCancelar(_, agendamentoId) {
    return new Promise((resolve, reject) => {
      http
        .post(`Agendamento/${agendamentoId}/Cancelar`)
        .then((resp) => {
          resolve(resp);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
