<template>
  <div>
    <div class="product-tab-menu-area mb-3 mt-1">
      <div class="container">
        <ul class="product-tab-menu nav nav-tabs">
          <li>
            <a href="#details" class="active" @click="$emit('aba', 'detalhe')" data-toggle="tab">
              <div class="thumb">
                <img :src="require('@/assets/images/product/tab1.png')" alt="product" />
              </div>
              <div class="content">Descrição</div>
            </a>
          </li>

          <li v-if="getLeilao.tipo != 'VENDA DIRETA'">
            <a href="#history" @click="$emit('aba', 'historico')" data-toggle="tab">
              <div class="thumb">
                <img :src="require('@/assets/images/product/tab3.png')" alt="product" />
              </div>
              <div class="content">Histórico de lances</div>
            </a>
          </li>
          <li>
            <a href="#anexos" @click="$emit('aba', 'anexos')" data-toggle="tab">
              <div class="thumb">
                <img :src="require('@/assets/images/product/anexos.png')" alt="product" />
              </div>
              <div class="content">Anexos</div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="container">
      <div class="tab-content">
        <div class="tab-pane fade show active" id="details">
          <div class="tab-details-content">
            <div class="header-area">
              <h3 class="title">{{ getLote.descricao }}</h3>
              <div class="item">
                <table class="product-info-table">
                  <tbody>
                    <tr v-for="(campo, index) in getLote.campos" :key="index">
                      <th>{{ campo.loteCampo.descricao }}</th>
                      <td>{{ campo.valor.toUpperCase() }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="item" v-if="getLote.local">
                <h5 class="subtitle">Localização:</h5>
                <p>
                  <!-- {{ getLote.local.endereco.logradouro }}
                  {{ getLote.local.endereco.numero }} -
                  {{ getLote.local.endereco.bairro }} - -->
                  {{ getLote.local.endereco.cidade }} -
                  {{ getLote.local.endereco.estado }}
                </p>
                <!-- <p>CEP: {{ getLote.local.endereco.cep }}</p> -->
              </div>
              <div class="item" v-if="getLote.descricaoDetalhada">
                <h5 class="subtitle">Descrição detalhada:</h5>
                <p v-html="getLote.descricaoDetalhada"></p>
              </div>
              <div class="item" v-if="getLote.judicial && getLote.loteJudicial">
                <h5 class="subtitle">Informações do Processo:</h5>
                <div class="row pt-2">
                  <div class="col-lg-2 col-sm-4" v-if="getLote.loteJudicial.numProcesso">
                    <b>Nº do Processo:</b>
                  </div>
                  <div class="col-lg-10 col-sm-8">
                    {{ getLote.loteJudicial.numProcesso }}
                  </div>
                </div>
                <div class="row pt-2">
                  <div class="col-lg-2 col-sm-4" v-if="getLote.loteJudicial.anoProcesso">
                    <b>Ano do Processo:</b>
                  </div>
                  <div class="col-lg-10 col-sm-8">
                    {{ getLote.loteJudicial.anoProcesso }}
                  </div>
                </div>
                <div class="row pt-2" v-if="getLote.loteJudicial.tipoAcao">
                  <div class="col-lg-2 col-sm-4">
                    <b>Tipo da Ação:</b>
                  </div>
                  <div class="col-lg-10 col-sm-8">
                    {{ getLote.loteJudicial.tipoAcao }}
                  </div>
                </div>
                <div class="row pt-2" v-if="getLote.loteJudicial.juizo">
                  <div class="col-lg-2 col-sm-4">
                    <b>Juizo:</b>
                  </div>
                  <div class="col-lg-10 col-sm-8">
                    {{ getLote.loteJudicial.juizo.nome }}
                  </div>
                </div>
                <div class="row pt-2" v-if="getLote.loteJudicial.vara">
                  <div class="col-lg-2 col-sm-4">
                    <b>Vara:</b>
                  </div>
                  <div class="col-lg-10 col-sm-8">
                    {{ getLote.loteJudicial.vara.nome }}
                  </div>
                </div>
                <div class="row pt-2" v-if="getLote.loteJudicial.autores.length > 0">
                  <div class="col-lg-2 col-sm-4">
                    <b>Autor:</b>
                  </div>
                  <div class="col-lg-10 col-sm-8">
                    {{ getNomesAutores }}
                  </div>
                </div>
                <div class="row pt-2" v-if="getLote.loteJudicial.reus.length > 0">
                  <div class="col-lg-2 col-sm-4">
                    <b>Réu:</b>
                  </div>
                  <div class="col-lg-10 col-sm-8">
                    {{ getNomesReus }}
                  </div>
                </div>
                <div class="row pt-2" v-if="getLote.loteJudicial.juizes.length > 0">
                  <div class="col-lg-2 col-sm-4">
                    <b>Juiz(a):</b>
                  </div>
                  <div class="col-lg-10 col-sm-8">
                    {{ getNomesJuizes }}
                  </div>
                </div>
                <div class="row pt-2" v-if="getLote.loteJudicial.escrivaes.length > 0">
                  <div class="col-lg-2 col-sm-4">
                    <b>Escrivão:</b>
                  </div>
                  <div class="col-lg-10 col-sm-8">
                    {{ getNomesEscrivaes }}
                  </div>
                </div>
                <div class="row pt-2" v-if="getLote.loteJudicial.fieisDepositarios.length > 0">
                  <div class="col-lg-2 col-sm-4">
                    <b>Fiel Depositário:</b>
                  </div>
                  <div class="col-lg-10 col-sm-8">
                    {{ getNomesFielDepositario }}
                  </div>
                </div>
                <div class="row pt-2" v-if="getLote.loteJudicial.partes.length > 0">
                  <div class="col-lg-2 col-sm-4">
                    <b>Partes:</b>
                  </div>
                  <div class="col-lg-10 col-sm-8">
                    {{ getNomesPartes }}
                  </div>
                </div>
                <div class="row pt-2" v-if="getLote.loteJudicial.credores.length > 0">
                  <div class="col-lg-2 col-sm-4">
                    <b>Credores:</b>
                  </div>
                  <div class="col-lg-10 col-sm-8">
                    {{ getNomesCredores }}
                  </div>
                </div>
                <div class="row pt-2" v-if="getLote.loteJudicial.localDepositario">
                  <div class="col-lg-2 col-sm-4">
                    <b>Local Depositário:</b>
                  </div>
                  <div class="col-lg-10 col-sm-8">
                    {{ getLote.loteJudicial.localDepositario }}
                  </div>
                </div>
                <div class="row pt-2" v-if="getLote.loteJudicial.advogados">
                  <div class="col-lg-2 col-sm-4">
                    <b>Advogados:</b>
                  </div>
                  <div class="col-lg-10 col-sm-8">
                    {{ getLote.loteJudicial.advogados }}
                  </div>
                </div>
                <div class="row pt-2" v-if="getLote.loteJudicial.comarca">
                  <div class="col-lg-2 col-sm-4">
                    <b>Comarca:</b>
                  </div>
                  <div class="col-lg-10 col-sm-8">
                    {{ getLote.loteJudicial.comarca }}
                  </div>
                </div>
                <div class="row pt-2" v-if="getLote.loteJudicial.natureza">
                  <div class="col-lg-2 col-sm-4">
                    <b>Natureza:</b>
                  </div>
                  <div class="col-lg-10 col-sm-8">
                    {{ getLote.loteJudicial.natureza }}
                  </div>
                </div>
              </div>
              <div class="item" v-if="(getLeilao.nomeLeiloeiro || getLeilao.nomeEmpresa) && !getLote.judicial">
                <h5 class="subtitle" v-if="getLeilao.tipo == 'VENDA DIRETA'">Vendido por:</h5>
                <h5 class="subtitle" v-else>Leiloado e Vendido por:</h5>
                <div class="row pt-2" v-if="getLeilao.nomeLeiloeiro">
                  <div class="col-auto">
                    <b>Leiloeiro Público Oficial:</b>
                  </div>
                  <div class="col-auto">
                    {{ getLeilao.nomeLeiloeiro }}
                  </div>
                </div>
                <div class="row pt-1">
                  <div class="col-auto">
                    <b>Empresa:</b>
                  </div>
                  <div class="col-auto">
                    {{ getLeilao.nomeEmpresa }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-pane fade show" id="history" v-if="getLeilao.tipo != 'VENDA DIRETA'">
          <div class="history-wrapper">
            <div class="item">
              <h5 class="title">
                Histórico de Lances
                <span class="badge badge-info">{{ getHistoricoLances.totalRecords }}</span>
              </h5>
              <div class="history-table-area">
                <table class="history-table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Usuário</th>
                      <th width="10%">Data</th>
                      <th>Status</th>
                      <th v-if="getLote.judicial">Pagamento</th>
                      <th>Lance</th>
                      <th>Taxas</th>
                      <th>Comissão({{ getLoteInfo.percentualComissao }})</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(lance, index) in listaHistoricos" :key="lance.lanceId">
                      <td data-history="#">
                        <!-- <img
                          :src="require('@/assets/images/dashboard/02.png')"
                          alt="Maior Lance"
                          title="Maior Lance"
                          width="25"
                          v-if="index === 0"
                        /> -->
                        <span style="padding-right: 10px"> {{ index + 1 }}&deg; </span>
                      </td>
                      <td data-history="Usuário">
                        <div class="user-info d-flex flex-column">
                          <div>
                            {{ lance.nomeUsuario }}
                          </div>

                          <div>
                            <small>{{ lance.cidade }}-{{ lance.uf }}</small>
                          </div>
                        </div>
                      </td>
                      <td data-history="Data">
                        {{ lance.dataLance | formatDate }}
                      </td>
                      <td data-history="Status">
                        <span class="badge badge-success" v-if="lance.status == 'Confirmado'">VÁLIDO</span>
                        <span class="badge badge-danger" v-else>CANCELADO</span>
                      </td>
                      <td data-history="Pagamento" v-if="getLote.judicial">
                        {{ lance.lanceParcelado ? 'PARCELADO' : 'À VISTA' }}
                      </td>
                      <td data-history="Lance">
                        {{
                          lance.valor
                            | currency('R$', 2, {
                              spaceBetweenAmountAndSymbol: true,
                              decimalSeparator: ',',
                              thousandsSeparator: '.',
                            })
                        }}
                      </td>
                      <td data-history="Taxas">
                        {{
                          lance.taxas
                            | currency('R$', 2, {
                              spaceBetweenAmountAndSymbol: true,
                              decimalSeparator: ',',
                              thousandsSeparator: '.',
                            })
                        }}
                      </td>
                      <td data-history="Comissao">
                        {{
                          lance.comissao
                            | currency('R$', 2, {
                              spaceBetweenAmountAndSymbol: true,
                              decimalSeparator: ',',
                              thousandsSeparator: '.',
                            })
                        }}
                      </td>

                      <td data-history="Total">
                        {{
                          lance.total
                            | currency('R$', 2, {
                              spaceBetweenAmountAndSymbol: true,
                              decimalSeparator: ',',
                              thousandsSeparator: '.',
                            })
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="text-center mb-3 mt-4">
                  <a href="#" onclick="return false;" class="button-3" @click="$emit('mais')">Mais</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade show" id="anexos" v-if="getLote.anexos">
          <div class="history-wrapper">
            <div class="item">
              <h5 class="title">Anexos</h5>
              <div class="history-table-area">
                <table class="history-table">
                  <thead>
                    <tr>
                      <th width="70%">Nome</th>
                      <th width="20%">Tamanho</th>
                      <th width="10%" class="text-center">Download</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="anexo in getLote.anexos" :key="anexo.arquivoId">
                      <td data-history="Nome">
                        <div class="user-info">
                          <div>
                            <a v-bind:href="anexo.arquivo.url" target="_blank">{{ anexo.nome }}</a>
                          </div>
                        </div>
                      </td>
                      <td data-history="Tamanho">{{ (anexo.arquivo.tamanho / 1000) | format_number }} KB</td>
                      <td data-history="Download" class="text-center">
                        <a v-bind:href="anexo.arquivo.url" target="_blank"><i class="fa fa-download"></i></a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
moment.locale('pt-br');

export default {
  data() {
    return {
      listaHistoricos: [],
    };
  },
  computed: {
    ...mapGetters(['getLotesLoading', 'getLote', 'getHistoricoLances', 'getHistoricoLancesLoading', 'getSignal', 'getLoteInfo', 'getLeilao']),
    getNomesAutores() {
      return this.getLote.loteJudicial.autores.map((x) => x.autor.pessoa.nomeCompleto).join(' e ');
    },
    getNomesJuizes() {
      return this.getLote.loteJudicial.juizes.map((x) => x.juiz.nomeCompleto).join(' e ');
    },
    getNomesReus() {
      return this.getLote.loteJudicial.reus.map((x) => x.reu.pessoa.nomeCompleto).join(' e ');
    },
    getNomesCredores() {
      return this.getLote.loteJudicial.credores.map((x) => x.credor.pessoa.nomeCompleto).join(' e ');
    },
    getNomesEscrivaes() {
      return this.getLote.loteJudicial.escrivaes.map((x) => x.escrivao.nomeCompleto).join(' e ');
    },
    getNomesFielDepositario() {
      return this.getLote.loteJudicial.fieisDepositarios.map((x) => x.fielDepositario.pessoa.nomeCompleto).join(' e ');
    },
    getNomesPartes() {
      return this.getLote.loteJudicial.partes.map((x) => x.parte.pessoa.nomeCompleto).join(' e ');
    },
  },
  watch: {
    getHistoricoLances() {
      if (this.getSignal) {
        this.listaHistoricos = [...this.getHistoricoLances.data];
      } else {
        this.listaHistoricos = [
          ...this.listaHistoricos,
          ...this.getHistoricoLances.data.filter((e) => !this.listaHistoricos.map((m) => m.lanceId).includes(e.lanceId)),
        ];
      }
      this.SET_SIGNAL();
    },
  },
  methods: {
    ...mapMutations(['SET_SIGNAL']),
  },

  filters: {
    formatDate(value) {
      if (value) {
        const utc = moment.utc(String(value)).toDate();
        return moment(utc).format('DD/MM/YYYY HH:mm:ss');
      }
    },
    format_number: function (value) {
      return parseInt(value).toLocaleString();
    },
  },
};
</script>

<style>
.info-leiloeiro h4 {
  font-size: 0.8rem;
  color: rgb(148, 148, 148);
  font-weight: 500;
}
</style>
