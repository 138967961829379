<template>
  <!-- Modal -->
  <div>
    <div
      class="modal fade"
      id="modalHabilitacaoEncerrada"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalCondicoesTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">ATENÇÃO</h5>
            <a
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </a>
          </div>
          <div class="modal-body">
            <div class="row text-center">
              <div class="col-12">
                <img
                  :src="require('@/assets/images/habilitacao_encerrada.png')"
                  class="pb-10"
                  width="64"
                />
                <p class="pt-2">{{ getLeilao.nome }}</p>
                <h6 class="my-4 p-2 alert alert-danger">
                  As Habilitações para este leilão estão encerradas!
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['getLeilao']),
  },
};
</script>

<style scoped>
.modal-body {
  padding: 20px;
}
</style>
