<template>
  <div>
    <section class="account-section padding-bottom padding-top">
      <div class="container">
        <div class="account-wrapper">
          <div class="left-side col-12">
            <div class="section-header">
              <h2 class="title">Confirmação de Conta</h2>
              <p>
                Caso não tenha recebido o email de confirmação da sua conta,
                informe seu email que iremos reenvia-lo.
              </p>
            </div>

            <form class="login-form" @submit.prevent="cadastro">
              <div class="row justify-content-center">
                <div class="form-group mb-30 col-12 is-invalid">
                  <label for="login-email"
                    ><i class="fas fa-envelope"></i
                  ></label>
                  <input
                    type="email"
                    id="login-email"
                    placeholder="Seu email cadastrado"
                    v-model="email"
                    required
                  />
                </div>
                <div
                  class="col-8 col-md-3 d-flex flex-column justify-content-center mb-0"
                >
                  <button
                    type="submit"
                    class="custom-button"
                    :disabled="cadastrando"
                  >
                    <i class="fa fa-spin fa-spinner" v-if="cadastrando"></i>
                    Enviar
                  </button>
                  <a href="/login" class="btn btn-link mt-3">Voltar</a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import notification from '@/mixins/notification';
import http from '@/service/http/http';
import { APP_URL } from '../../../config/config';

moment.locale('pt-br');

export default {
  data() {
    return {
      email: '',
      cadastrando: false,
    };
  },
  mixins: [notification],
  computed: {
    ...mapGetters([]),
  },
  methods: {
    ...mapActions([]),
    async cadastro() {
      this.error = '';
      this.cadastrando = true;
      if (this.email == '') {
        this.showToast({ title: 'Insira um email válido', type: 'warning' });
        this.cadastrando = false;
      }
      const payload = {
        email: this.email,
        returnUrl: APP_URL + 'login?emailConfirmado',
      };
      try {
        await http.post(`usuario/ReenviarConfirmacaoEmail`, payload);
        this.showToast({ title: 'Confirmação de email enviada' });
        this.cadastrando = false;
      } catch (error) {
        const erro = error.response.data.Errors.Exception;

        if (erro.includes('Email já confirmado')) {
          this.showToast({ title: 'Email já confirmado', type: 'error' });
        } else if (erro.includes('Usuario não encontrado')) {
          this.showToast({ title: 'Usuario não encontrado', type: 'error' });
        } else {
          this.showToast({ title: 'Não foi possivel enviar', type: 'error' });
        }
        this.cadastrando = false;
      }
    },
  },
};
</script>

<style scoped>
.btn-link {
  color: #171d1c;
}
@media (max-width: 576px) {
  .account-section {
    padding-top: 180px;
  }
}
</style>
