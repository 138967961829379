<template>
  <div class="d-flex justify-content-center">
    <div class="auction-item-6 new-auction-item-6 col-xl-12 col-lg-6 col-12 col-md-12 flex-wrap d-flex card-lotes">
      <div class="auction-thumb col-sm-12 col-md-12 col-xl-4">
        <a :href="'/lote/' + lote.loteId" class="thumb-img">
          <img v-if="!lote.foto" :src="require('@/assets/images/sem_foto.png')" alt="lote" class="foto" />
          <img v-else :src="lote.foto.arquivo.url" :title="lote.descricao" class="foto" />
          <div class="field-badge">
            <tipo-lote :tipoLote="lote.tipoLote"></tipo-lote>
          </div>
        </a>
      </div>
      <div class="auction-inner new-auction-inner col-sm-12 col-md-12 col-xl-8">
        <div class="auction-content col-sm-12 col-md-12 col-xl-8">
          <div class="title">
            <div class="pb-1">
              <span>Lote: {{ lote.numeroLote }}</span>
            </div>
            <h5>
              <a :href="'/lote/' + lote.loteId">{{ lote.descricao }}</a>
            </h5>
            <small v-if="lote.local"
              >Localização: <span>{{ lote.local.endereco.cidade }} / {{ lote.local.endereco.estado }}</span></small
            >
          </div>

          <div class="row text" v-if="!lote.judicial">
            <div class="col-12 col-lg-6" v-for="(campo, index) in campos" :key="index">
              <div class="row">
                <div class="col-4 col-xl-12 campo-text text-left">
                  <strong style="padding-bottom: 0">{{ campo.loteCampo.descricao }}:</strong>
                </div>
                <div class="col-8 col-xl-12 text-truncate text-left">
                  {{ campo.valor }}
                </div>
              </div>
            </div>
          </div>
          <div class="row text" v-if="lote.judicial">
            <div class="col-12 col-lg-6" v-if="lote.loteJudicial.numProcesso">
              <div class="row">
                <div class="col-4 col-xl-12 campo-text text-left">
                  <strong style="padding-bottom: 0">Nº do Processo:</strong>
                </div>
                <div class="col-8 col-xl-12 text-truncate text-left">
                  {{ lote.loteJudicial.numProcesso }}
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6" v-if="lote.loteJudicial.anoProcesso">
              <div class="row">
                <div class="col-4 col-xl-12 campo-text text-left">
                  <strong style="padding-bottom: 0">Ano do Processo:</strong>
                </div>
                <div class="col-8 col-xl-12 text-truncate text-left">
                  {{ lote.loteJudicial.anoProcesso }}
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6" v-if="lote.loteJudicial.tipoAcao">
              <div class="row">
                <div class="col-4 col-xl-12 campo-text text-left">
                  <strong style="padding-bottom: 0">Tipo da Ação:</strong>
                </div>
                <div class="col-8 col-xl-12 text-truncate text-left">
                  {{ lote.loteJudicial.tipoAcao }}
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6" v-if="lote.loteJudicial.juizo.nome">
              <div class="row">
                <div class="col-4 col-xl-12 campo-text text-left">
                  <strong style="padding-bottom: 0">Juizo:</strong>
                </div>
                <div class="col-8 col-xl-12 text-truncate text-left">
                  {{ lote.loteJudicial.juizo.nome }}
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6" v-if="lote.loteJudicial.vara.nome">
              <div class="row">
                <div class="col-4 col-xl-12 campo-text text-left">
                  <strong style="padding-bottom: 0">Vara:</strong>
                </div>
                <div class="col-8 col-xl-12 text-truncate text-left">
                  {{ lote.loteJudicial.vara.nome }}
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6" v-if="lote.loteJudicial.comarca">
              <div class="row">
                <div class="col-4 col-xl-12 campo-text text-left">
                  <strong style="padding-bottom: 0">Comarca:</strong>
                </div>
                <div class="col-8 col-xl-12 text-truncate text-left">
                  {{ lote.loteJudicial.comarca }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="auction-bidding col-sm-12 col-md-12 col-xl-4">
          <div class="auction-inner bid-amount" id="amount-current">
            <div class="box-amount">
              <div class="icon">
                <i class="flaticon-money"></i>
              </div>
              <div class="amount-content">
                <div class="current" v-if="getLeilao.tipo == 'VENDA DIRETA'">Valor</div>
                <div class="current" v-else-if="lote.info.qtdLances === 0">Lance Inicial</div>
                <div class="current" v-else>Último Lance</div>

                <div class="amount" v-if="getLeilao.tipo != 'VENDA DIRETA'">
                  {{
                    lote.info.ultimoLance
                      | currency('R$', 2, {
                        spaceBetweenAmountAndSymbol: true,
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </div>
                <div class="amount" v-else>
                  {{
                    lote.valorLanceInicial
                      | currency('R$', 2, {
                        spaceBetweenAmountAndSymbol: true,
                        decimalSeparator: ',',
                        thousandsSeparator: '.',
                      })
                  }}
                </div>
              </div>
            </div>
            <div class="bids-area">
              <span class="total-bids" v-if="getLeilao.tipo != 'VENDA DIRETA'">{{ lote.info ? lote.info.qtdLances : 0 }} Lances</span>
              <span class="total-bids" v-if="getLeilao.tipo == 'VENDA DIRETA'">{{ lote.info ? lote.info.qtdLances : 0 }} Propostas</span>
            </div>
          </div>
          <div class="judicial" v-if="lote.judicial">Judicial</div>
          <a :href="'/lote/' + lote.loteId" class="custom-button">
            <span v-if="getLeilao.tipo != 'VENDA DIRETA'"> DAR LANCE </span>
            <span v-if="getLeilao.tipo == 'VENDA DIRETA'">FAZER PROPOSTA</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TipoLote from '@/components/TipoLote.vue';
import http from '../service/http/http';
export default {
  components: {
    TipoLote,
  },
  props: {
    lote: {
      type: Object,
    },
  },
  data() {
    return {
      campos: [],
      loteInfo: '',
      colorPrimary: process.env.VUE_APP_PRIMARY_COLOR,
      colorSecondary: process.env.VUE_APP_SECONDARY_COLOR,
      colorConstrast: process.env.VUE_APP_CONSTRAST_COLOR,
    };
  },
  computed: {
    ...mapGetters(['getLeilao']),
    status() {
      return this.lote.status;
    },
  },
  methods: {
    getLoteInfo(loteId) {
      return http({
        url: `Lote/${loteId}/info`,
        method: 'get',
      });
    },
    selecionaLote() {
      this.$router.push({ name: 'product-details' });
    },
  },
  created() {
    const maxSize = this.lote.campos.length < 6 ? this.lote.campos.length : 6;
    this.campos = this.lote.campos
      .filter((f) => f.loteCampo.destaqueSite)
      .sort((a, b) => {
        if (a.loteCampo.ordemExibicao > b.loteCampo.ordemExibicao) {
          return 1;
        }
        if (a.loteCampo.ordemExibicao < b.loteCampo.ordemExibicao) {
          return -1;
        }
        return 0;
      })
      .slice(0, maxSize);
  },
};
</script>

<style scoped>
.foto {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}
.text {
  font-size: 14px;
}

.box-amount {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
}

.caracteristicas strong {
  padding-bottom: 0;
}

.judicial {
  line-height: 1.6em;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  text-align: center;
  border-radius: 50px;
  background-color: #0783ae;
  margin-top: 10px;
}

.auction-bidding {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bid-amount {
  border-radius: 28px 28px 0px 0px;
  width: 100%;
  justify-content: space-evenly !important;
  flex-direction: column;
  align-items: center !important;
  height: 100%;
}

.amount-content {
  padding-left: 0;
}

.field-badge {
  width: 92%;
  bottom: 0px;
  height: 30px;
  position: absolute;
}

.badge {
  display: flex;
  border-radius: 15px;
  height: 35px;
  font-size: 100%;
  justify-content: center;
  align-items: center;
}

.heigth-badge {
  height: 35px;
}

.thumb-img {
  height: 259px;
  width: 100%;
  min-width: 340px;
  background-color: #eeeeee;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.36);
}

img {
  border-radius: 20px 20px 0px 0px;
}

.custom-button {
  width: 100%;
  bottom: 70px;
  position: absolute;
  bottom: 10px;
  background-color: v-bind(colorPrimary);
  color: v-bind(colorConstrast);
}
@media (max-width: 1199px) {
  img {
    border-radius: 20px 20px 0px 0px;
  }

  .bid-amount {
    justify-content: center !important;
  }
  .field-badge {
    bottom: -7px;
    z-index: 5;
    width: 100%;
  }

  .auction-item-6,
  .auction-inner {
    border-radius: 0px 0px 28px 28px !important;
  }

  .auction-thumb {
    padding-left: 0px;
    padding-right: 0px;
  }

  .auction-thumb a {
    width: 100%;
  }

  #amount-current {
    border-radius: 28px 28px 0px 0px !important;
  }

  .title {
    text-align: center !important;
  }

  .campo-text {
    text-align: right !important;
  }
}
@media (min-width: 1200px) {
  .new-auction-item-6 .new-auction-inner {
    width: 880px;
  }
}
</style>
