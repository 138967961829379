<template>
  <div class="countdown-area">
    <div class="countdown" :class="{ 'color-yellow': lastMinute , 'color-red' : LastThirtySec}">
      <div>{{ menssagem }}</div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
moment.locale("pt-br");
export default {
  props: {
    final: {
      type: String,
    },
    atual: {
      type: String,
    },
  },
  data() {
    return {
      menssagem: "0s",
      timer : null,
      lastMinute: false,
      LastThirtySec: false
    };
  },
  methods: {
    countDown(dataFechamento) {
      const final = moment.utc(dataFechamento) || moment.utc(this.final);
      const atual = moment.utc(this.atual);
      this.lastMinute = false;
      this.LastThirtySec = false;

      if (final.diff(atual, "second") <= 0) {
        this.menssagem = "Encerrado";
        this.$emit("FinishCountdown", true);
        return;
      }

      var duracao;
      duracao = moment.duration(final.diff(atual, 'ms', true), 'ms');

      this.menssagem = (duracao.get("hours") > 0 ? duracao.get("hours") + "h : " : "") +
                       (duracao.get("minutes") > 0 ? duracao.get("minutes") + "m : " : "") +
                       duracao.get("seconds") + "s";

      this.$emit("FinishCountdown", false);      
      
      this.timer = setInterval(() => {
        final.subtract(1, "seconds");
        duracao = moment.duration(final.diff(atual, 'ms', true), 'ms');

        this.menssagem =
          (duracao.get("hours") > 0 ? duracao.get("hours") + "h : " : "") +
          (duracao.get("minutes") > 0 ? duracao.get("minutes") + "m : " : "") +
          duracao.get("seconds") + "s";

        if (final.diff(atual, "second") === 0) {
          clearInterval(this.timer);
          this.$emit("FinishCountdown", true);
          return;
        }

        if (final.diff(atual, "second") < 16) {
          this.$emit("LastMinute");
          this.lastMinute = true;
        }

        if (final.diff(atual, "second") < 11 && final.diff(atual, "second") >= 6) {
          this.$emit("LastThirtySec");
          this.lastMinute = false;
          this.LastThirtySec = true;
        }

        if (final.diff(atual, "second") < 6 && final.diff(atual, "second") >= 0) {
          this.$emit("LastTenSec");
          this.lastMinute = false;
          this.LastThirtySec = true;
        }
      }, 1000);
    },
  },
  watch: {
    final : {
      immediate : true,
      handler(value) {
        if(this.timer){
          clearInterval(this.timer)
        }
        this.countDown(value);
      }
    }
  },
  beforeDestroy(){
    clearInterval(this.timer)
  }  
};
</script>

<style>
  .countdown {
    font-weight: bold;
  }

  .color-yellow {
    color: #F9C718
  }

  .color-red {
    color: #E0280F;
  }
</style>
