<template>
  <div>
    <div class="row mt-5">
      <div class="col-lg-8">
        <div class="product-details-content">
          <div class="product-details-header">
            <vue-skeleton-loader v-if="getLotesLoading" type="rect" animation="fade" :width="320" :height="43" class="mb-2" />
            <h2 class="title" v-else>{{ getLote.descricao }}</h2>
            <div v-if="getLote.status && getLote.status.descricao == 'Removido'" class="removido">REMOVIDO DO LEILÃO</div>
          </div>
          <ul class="price-table mb-30">
            <li class="header">
              <h5 class="current">
                <span v-if="getLeilao.tipo == 'VENDA DIRETA'">Valor do Lote</span>
                <span v-else-if="getLoteInfo.qtdLances === 0">Lance Inicial</span>
                <span v-else>Último Lance</span>
              </h5>

              <vue-skeleton-loader v-if="getLotesLoading" type="rect" animation="fade" :width="150" :height="46" />

              <h3 :class="!atingiuMinimoVenda ? 'text-danger' : 'price'" v-else-if="getLeilao.tipo != 'VENDA DIRETA'">
                {{
                  getLoteInfo.ultimoLance
                    | currency('R$', 2, {
                      spaceBetweenAmountAndSymbol: true,
                      decimalSeparator: ',',
                      thousandsSeparator: '.',
                    })
                }}
                {{ !atingiuMinimoVenda ? '*' : '' }}
              </h3>
              <h3 class="price" v-else>
                {{
                  getLote.valorLanceInicial
                    | currency('R$', 2, {
                      spaceBetweenAmountAndSymbol: true,
                      decimalSeparator: ',',
                      thousandsSeparator: '.',
                    })
                }}
              </h3>
            </li>
            <li v-if="getLoteInfo.qtdLances > 0 && getLeilao.tipo != 'VENDA DIRETA'">
              <span class="details"
                >Total com taxas <br class="d-lg-none d-md-none" /><small class="text-muted small">
                  (Lance + Taxa Adm.<template v-if="getLeilao.comissao > 0"> + Comissão</template>)
                </small></span
              >
              <vue-skeleton-loader v-if="getLotesLoading" type="rect" animation="fade" :width="100" :height="28" />
              <h5 class="info" v-else>
                {{
                  getLoteInfo.valorTotal
                    | currency('R$', 2, {
                      spaceBetweenAmountAndSymbol: true,
                      decimalSeparator: ',',
                      thousandsSeparator: '.',
                    })
                }}
              </h5>
            </li>
            <li v-if="getLoteInfo.qtdLances > 0 && getLeilao.tipo != 'VENDA DIRETA'">
              <span class="details">Ganhador atual</span>
              <h5 class="info">
                <vue-skeleton-loader v-if="getLotesLoading" type="rect" animation="fade" :width="100" :height="25" />
                <small v-else>{{ getLoteInfo.usuarioUltimoLance }}</small>
              </h5>
            </li>
            <li v-if="getLeilao.tipo != 'VENDA DIRETA'">
              <span class="details">Incremento de lance</span>
              <vue-skeleton-loader v-if="getLotesLoading" type="rect" animation="fade" :width="100" :height="28" />
              <h5 class="info" v-else>
                {{
                  getLote.valorIncremento
                    | currency('R$', 2, {
                      spaceBetweenAmountAndSymbol: true,
                      decimalSeparator: ',',
                      thousandsSeparator: '.',
                    })
                }}
              </h5>
            </li>
            <li v-if="getLote.valorMinimoVenda > 0">
              <span class="details">Mínimo Condicional</span>
              <vue-skeleton-loader v-if="getLotesLoading" type="rect" animation="fade" :width="100" :height="28" />
              <h5 class="info" v-else>
                {{
                  getLote.valorMinimoVenda
                    | currency('R$', 2, {
                      spaceBetweenAmountAndSymbol: true,
                      decimalSeparator: ',',
                      thousandsSeparator: '.',
                    })
                }}
              </h5>
            </li>
          </ul>
          <div v-if="!atingiuMinimoVenda && !getLotesLoading && getLeilao.tipo != 'VENDA DIRETA'" class="alert alert-danger">
            <small
              >(*) LOTE CONDICIONAL - Lote ainda não superou o valor mínimo. Ao arrematar o lote nessas condições, o Comitente poderá realizar a venda
              ou não.</small
            >
          </div>
          <div v-if="abertoParaLances">
            <div v-if="!getAuth" class="text-center">
              <h6 v-if="getLeilao.tipo == 'VENDA DIRETA'">É necessário entrar no sistema para fazer uma proposta</h6>
              <h6 v-if="getLeilao.tipo != 'VENDA DIRETA'">É necessário entrar no sistema para fazer um lance</h6>
              <a href="#" class="custom-button mt-2" @click="goToLogin"> Login </a>
            </div>
            <div
              class="product-bid-area"
              v-if="
                getAuth && habilitado && !encerrado
                // ((getUsuarioInfo.tipoPessoa == 'PJ' && getLote.judicial) ||
                //   !getLote.judicial)
              "
            >
              <div class="d-flex" style="line-height: 37px" v-if="getLeilao.lanceParcelado">
                <i class="fa fa-info-circle mr-1 info" @click="showInfo"></i>
                <label for="myonoffswitch" v-if="getLeilao.tipo == 'VENDA DIRETA'"><b>PROPOSTA PARCELADA:</b></label>
                <label for="myonoffswitch" v-else><b>LANCE PARCELADO:</b></label>

                <div class="msg" v-show="showingInfo">
                  Possibilidade de realizar o parcelamento do bem conforme o Artigo 895 do CPC, Deverá ser informado o valor de entrada e a quantidade
                  de parcelas desejadas.
                </div>
                <div class="onoffswitch ml-2">
                  <input type="checkbox" name="onoffswitch" class="onoffswitch-checkbox" id="myonoffswitch" tabindex="0" v-model="lanceParcelado" />
                  <label class="onoffswitch-label" for="myonoffswitch"></label>
                </div>
              </div>
              <div class="row mb-3" v-if="lanceParcelado">
                <div class="col-md-4 form-group">
                  <label for="entrada">Valor da Entrada</label>
                  <money v-model="valorEntrada" v-bind="money" placeholder="Insira o valor do seu lance"></money>
                </div>
                <div class="col-md-4 form-group">
                  <label for="parcela">Parcelas</label>
                  <select class="form-control" id="parcela" v-model="parcelas">
                    <option :value="item" :key="item" v-for="item in qtdParcelas()">
                      {{ item }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4 form-group">
                  <label for="indice">Índice de Correção</label>
                  <select class="form-control" id="indice" v-model="indiceCorrecao">
                    <option v-for="item in getLeilao.configuracaoParcela.indiceCorrecao" :key="item.indiceCorrecaoId" :value="item.indiceCorrecaoId">
                      {{ item.indiceCorrecao.descricao + '(' + item.indiceCorrecao.indiceCorrecao + '%)' }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="product-bid-form">
                <div class="search-icon">
                  <img :src="require('@/assets/images/product/search-icon.png')" alt="product" />
                </div>
                <div class="text-center">
                  <money v-model="valor" v-bind="money" placeholder="Insira o valor do seu lance" readonly></money>

                  <button class="custom-button" style="padding: 0 0px; width: 50px" @click="incremento" v-if="getLeilao.tipo != 'VENDA DIRETA'">
                    +
                  </button>
                  <button class="custom-button" style="padding: 0 0; width: 50px" @click="decremento" v-if="getLeilao.tipo != 'VENDA DIRETA'">
                    -
                  </button>
                  <button
                    class="custom-button"
                    @click="fazerLance"
                    :disabled="getLanceLoading"
                    style="width: 190px"
                    v-if="getLeilao.tipo != 'VENDA DIRETA'"
                  >
                    <i class="fa fa-spin fa-spinner" v-show="getLanceLoading"></i>
                    Enviar
                  </button>
                  <button
                    class="custom-button"
                    @click="fazerProposta"
                    :disabled="getLanceLoading"
                    style="width: 250px"
                    v-if="getLeilao.tipo == 'VENDA DIRETA'"
                  >
                    <i class="fa fa-spin fa-spinner" v-show="getLanceLoading"></i>
                    Enviar Proposta
                  </button>
                </div>
              </div>
            </div>
            <div class="alert alert-danger mt-3" v-for="error in getLanceError" :key="error">
              {{ error }}
            </div>
            <!-- <div
              v-if="
                getAuth &&
                habilitado &&
                !encerrado &&
                getUsuarioInfo.tipoPessoa == 'PF' &&
                getLote.judicial
              "
            >
              <div class="alert alert-info mt-3">
                LOTE DISPONÍVEL SOMENTE PARA PESSOA JURÍDICA HABILITADA
              </div>
            </div> -->
            <div v-if="getAuth && !encerrado && !habilitado">
              <div class="product-bid-area">
                <div class="text-center">
                  <a id="mostrarModarCondicoes" v-show="false" data-target="#modalCondicoes" data-toggle="modal"> </a>
                  <a
                    href="javascript:void(0)"
                    class="custom-button"
                    @click="abreModalHabilitar"
                    v-if="getLeilaoHabilitado.status !== 'Pendente' && !getLeilao.habilitacaoDesativada"
                  >
                    Habilite-se
                  </a>
                  <a
                    href="javascript:void(0)"
                    class="custom-button"
                    data-target="#modalHabilitacaoEncerrada"
                    data-toggle="modal"
                    v-if="getLeilaoHabilitado.status !== 'Pendente' && getLeilao.habilitacaoDesativada"
                  >
                    Habilite-se
                  </a>
                  <a class="custom-button btn-warning text-white" v-if="getLeilaoHabilitado.status === 'Pendente'"> Habilitação Em Análise </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="product-sidebar-area">
          <info-loader v-if="getLoteInfoLoading" />

          <div
            class="product-single-sidebar mb-3"
            :class="{
              'box-pulse-yellow': ultimoMinuto || getLoteInfo.status === 'Dou-lhe uma',
              'box-pulse-red': ultimosTrintaSeg || getLoteInfo.status === 'Dou-lhe duas',
              'box-pulse10-red': ultimosDezSeg,
            }"
            v-else
          >
            <status @FinishCountdown="setFinishCountdown" @LastMinute="setLastMinute" @LastThirtySec="setLastThirtySec" @LastTenSec="setLastTenSec" />

            <div class="side-counter-area">
              <div class="side-counter-item">
                <div class="thumb">
                  <img :src="require('@/assets/images/product/icon1.png')" alt="product" />
                </div>
                <div class="content">
                  <h3 class="count-title">
                    <span class="counter">{{ getLoteInfo.qtdParticipantes }}</span>
                  </h3>
                  <p>Participantes</p>
                </div>
              </div>
              <div class="side-counter-item">
                <div class="thumb">
                  <img :src="require('@/assets/images/product/icon2.png')" alt="product" />
                </div>
                <div class="content">
                  <h3 class="count-title">
                    <span class="counter">{{ getLoteInfo.qtdVisualizacoes }}</span>
                  </h3>
                  <p>Visualizações</p>
                </div>
              </div>
              <div class="side-counter-item">
                <div class="thumb">
                  <img :src="require('@/assets/images/product/icon3.png')" alt="product" />
                </div>
                <div class="content">
                  <h3 class="count-title">
                    <span class="counter">{{ getLoteInfo.qtdLances }}</span>
                  </h3>
                  <p v-if="getLeilao.tipo == 'VENDA DIRETA'">Propostas</p>
                  <p v-else>Lances</p>
                </div>
              </div>
            </div>
          </div>
          <!-- <a href="/termos-de-uso" class="cart-link"
            >Ver termos e condições de uso.</a
          > -->
        </div>
        <div class="buy-now-area">
          <div class="share-area pb-2">
            <span>Compartilhe:</span>
            <ul>
              <li>
                <a
                  :href="'https://www.facebook.com/sharer/sharer.php?u=#' + host.replace('https://', '') + '/lote/' + getLoteIdSelecionado"
                  rel="nofollow"
                  target="_blank"
                >
                  <i class="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a :href="'https://api.whatsapp.com/send?text=Veja este lote que interessante! ' + host + '/lote/' + getLoteIdSelecionado"
                  ><i class="fab fa-whatsapp"></i
                ></a>
              </li>
              <!-- <li>
                <a href="javascript:void(0)"><i class="fab fa-linkedin-in"></i></a>
              </li>
              <li>
                <a href="javascript:void(0)"><i class="fab fa-instagram"></i></a>
              </li> -->
            </ul>
          </div>
        </div>
      </div>

      <ModalCondicoes tabInicio="condicoes" />
      <modal-verifica-identidade v-if="getLeilao && getUsuarioInfo" />
      <modal-habilitacao-encerrada />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { Money } from 'v-money';
import VueSkeletonLoader from 'skeleton-loader-vue';
import InfoLoader from './InfoLoader.vue';
import Status from './StatusLote.vue';
import ModalCondicoes from '@/components/ModalCondicoes.vue';
import ModalVerificaIdentidade from '@/components/ModalVerificaIdentidade.vue';
import ModalHabilitacaoEncerrada from '@/components/ModalHabilitacaoEncerrada.vue';
import { ORIGIN } from '../../../config/config';
import moment from 'moment';
moment.locale('pt-br');

export default {
  props: {
    loteId: {
      type: String,
    },
  },
  components: {
    Money,
    VueSkeletonLoader,
    InfoLoader,
    Status,
    ModalCondicoes,
    ModalVerificaIdentidade,
    ModalHabilitacaoEncerrada,
  },
  data() {
    return {
      valor: 0,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
      isFinishCountdown: false,
      ultimoMinuto: false,
      ultimosTrintaSeg: false,
      ultimosDezSeg: false,
      loteIdSelecionado: this.loteId,
      showingInfo: false,
      timer: null,
      lanceParcelado: false,
      valorEntrada: 0,
      indiceCorrecao: 0,
      parcelas: 0,
      host: ORIGIN,
    };
  },
  watch: {
    getLoteInfo() {
      this.ultimoMinuto = false;
      this.ultimosTrintaSeg = false;
      this.ultimosDezSeg = false;
    },
    getLoteIdSelecionado() {
      this.loteIdSelecionado = this.getLoteIdSelecionado;
      this.valor = 0;
      this.$store.commit('SET_LANCE_ERROR');
      this.$emit('atualizaHistorico');
    },
  },
  computed: {
    ...mapGetters([
      'getAuth',
      'getLotesLoading',
      'getLote',
      'getLoteInfo',
      'getLoteInfoLoading',
      'getLanceError',
      'getLanceLoading',
      'getLoteIdSelecionado',
      'getLeilaoHabilitado',
      'getLeilao',
      'getCadastroLoading',
      'getLoteHabilitado',
      'getUsuarioInfo',
      'getNumerosLotes',
    ]),
    habilitado() {
      return this.getLeilao.regrasHabilitacao && this.getLeilaoHabilitado.habilitado;
    },
    abertoParaLances() {
      return (
        this.getLoteInfo.status &&
        (this.getLoteInfo.status.includes('Aberto') || this.getLoteInfo.status === 'Aguardando Fechamento' || this.getLoteInfo.emFechamento)
      );
    },
    atingiuMinimoVenda() {
      return (
        this.getLote.valorMinimoVenda === 0 ||
        this.getLoteInfo.qtdLances === 0 ||
        (this.getLoteInfo.ultimoLance >= this.getLote.valorMinimoVenda && this.getLoteInfo.qtdLances > 0)
      );
    },
    encerrado() {
      return this.isFinishCountdown || this.getLoteInfo.status === 'Encerrado' || this.getLoteInfo.status === 'Fechado para lances';
    },
  },
  methods: {
    ...mapMutations(['SET_SIGNAL', 'SET_LOTEID_SELECIONADO']),
    ...mapActions([
      'setLoteInfo',
      'setLote',
      'lance',
      'proposta',
      'setHistoricoLances',
      'info',
      // "verificarAcessoLeilao",
      'setNumerosLotes',
    ]),
    qtdParcelas() {
      const parcelas = [];
      for (let i = 1; i <= this.getLeilao.configuracaoParcela.maximoParcelas; i++) {
        parcelas.push(i);
      }

      return parcelas.reverse();
    },
    showInfo() {
      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.showingInfo = !this.showingInfo;

      if (this.showingInfo) {
        this.timer = setTimeout(() => {
          this.showingInfo = false;
        }, 2000);
      }
    },
    async abreModalHabilitar() {
      if (this.getAuth) {
        await this.info();
      }
      this.$store.commit('SET_LEILAO_ERRO');
      document.querySelector("[data-target='#modalCondicoes']").click();
    },
    async fazerLance() {
      // await this.setLoteInfo(this.loteId);
      if (!this.valor || typeof this.valor !== 'number' || this.valor < 0) return;

      const { ultimoLance, qtdLances } = this.getLoteInfo;
      const { valorIncremento } = this.getLote;

      if (this.lanceParcelado) {
        if (this.valorEntrada <= 0 || this.valorEntrada < this.valor * (this.getLeilao.configuracaoParcela.minimoEntrada / 100)) {
          this.$store.commit('SET_LANCE_ERROR', [
            'O Valor da Entrada deve ser maior ou igual a ' + this.getLeilao.configuracaoParcela.minimoEntrada + '% do valor do Lance',
          ]);
          return;
        }

        if (this.parcelas <= 0) {
          this.$store.commit('SET_LANCE_ERROR', ['Selecione o numero de parcelas desejada para o lance parcelado']);
          return;
        }

        if (this.indiceCorrecao <= 0) {
          this.$store.commit('SET_LANCE_ERROR', ['Selecione o índice de correção desejada para o lance parcelado']);
          return;
        }
      } else {
        if (this.valor < ultimoLance) {
          this.$store.commit('SET_LANCE_ERROR', ['Valor do lance deve ser maior que o ' + (qtdLances == 0 ? 'lance inicial' : 'último lance')]);
          return;
        }

        if (this.valor == ultimoLance && qtdLances > 0) {
          this.$store.commit('SET_LANCE_ERROR', ['Valor do lance deve ser maior que o último lance']);
          return;
        }

        if (this.valor - ultimoLance < valorIncremento && qtdLances > 0) {
          this.$store.commit('SET_LANCE_ERROR', ['A diferença entre seu lance e o último lance deve ser maior ou igual o incremento mínimo']);
          return;
        }
      }

      const payload = {
        loteId: this.getLoteIdSelecionado,
        valor: this.valor,
        lanceParcelado: this.lanceParcelado,
        valorEntrada: this.valorEntrada,
        parcelas: this.parcelas,
        indiceCorrecaoId: this.indiceCorrecao,
      };

      await this.lance(payload);

      if (!this.getLanceError) {
        this.valor = 0;
        this.lanceParcelado = false;
        this.valorEntrada = 0;
        this.parcelas = 0;
        this.indiceCorrecaoId = 0;
      }
    },
    async fazerProposta() {
      if (!this.valor || typeof this.valor !== 'number' || this.valor < 0) return;

      if (this.lanceParcelado) {
        if (this.valorEntrada <= 0 || this.valorEntrada < this.valor * (this.getLeilao.configuracaoParcela.minimoEntrada / 100)) {
          this.$store.commit('SET_LANCE_ERROR', [
            'O Valor da Entrada deve ser maior ou igual a ' + this.getLeilao.configuracaoParcela.minimoEntrada + '% do valor da proposta',
          ]);
          return;
        }

        if (this.parcelas <= 0) {
          this.$store.commit('SET_LANCE_ERROR', ['Selecione o numero de parcelas desejada para o proposta parcelada']);
          return;
        }

        if (this.indiceCorrecao <= 0) {
          this.$store.commit('SET_LANCE_ERROR', ['Selecione o índice de correção desejada para o proposta parcelada']);
          return;
        }
      }

      const payload = {
        loteId: this.getLoteIdSelecionado,
        valor: this.valor,
        lanceParcelado: this.lanceParcelado,
        valorEntrada: this.valorEntrada,
        parcelas: this.parcelas,
        indiceCorrecaoId: this.indiceCorrecao,
      };

      await this.proposta(payload);

      if (!this.getLanceError) {
        this.valor = 0;
        this.lanceParcelado = false;
        this.valorEntrada = 0;
        this.parcelas = 0;
        this.indiceCorrecaoId = 0;
      }
    },
    async incremento() {
      const { ultimoLance, qtdLances } = this.getLoteInfo;
      const { valorIncremento } = this.getLote;

      if (this.valor === 0 || this.valor < ultimoLance) {
        this.valor = ultimoLance;
        if (qtdLances > 0) {
          this.valor += valorIncremento;
        }
      } else {
        this.valor += valorIncremento;
      }
    },
    async decremento() {
      if (typeof this.valor !== 'number') return;

      const { ultimoLance, qtdLances } = this.getLoteInfo;
      const { valorIncremento } = this.getLote;

      const valor = this.valor - this.getLote.valorIncremento;

      if (qtdLances == 0 && valor < ultimoLance) return;
      if (qtdLances > 0 && valor < ultimoLance + valorIncremento) return;

      if (valor < 0) return;
      this.valor = valor;
    },
    async navLote(loteId) {
      this.SET_SIGNAL(true);
      this.SET_LOTEID_SELECIONADO(loteId);
      window.history.pushState('', '', '' + loteId);
      this.$store.commit('SET_LOTE_INFO_LOADING', true);
      this.valor = 0;
      this.loteIdSelecionado = loteId;

      this.isFinishCountdown = false;
      this.ultimoMinuto = false;
      this.ultimosTrintaSeg = false;
      this.ultimosDezSeg = false;

      await this.setLote(loteId);
      await this.setLoteInfo(loteId);
    },
    setFinishCountdown(isFinish) {
      this.isFinishCountdown = isFinish;
      this.ultimoMinuto = false;
      this.ultimosTrintaSeg = false;
      this.ultimosDezSeg = false;
    },
    setLastMinute() {
      this.ultimoMinuto = true;
    },
    setLastThirtySec() {
      this.ultimoMinuto = false;
      this.ultimosTrintaSeg = true;
    },
    setLastTenSec() {
      this.ultimoMinuto = false;
      this.ultimosTrintaSeg = false;
      this.ultimosDezSeg = true;
    },
    goToLogin() {
      this.$router.push({
        path: '/login',
        query: { returnUrl: this.$route.path },
      });
    },
    onEventNewLance(loteinfo) {
      if (this.getLote.loteId === loteinfo.loteId) {
        this.isFinishCountdown = false;
      }
    },
  },
  created() {
    this.setLoteInfo(this.loteId);
    this.ultimoMinuto = false;
    this.ultimosTrintaSeg = false;
    this.ultimosDezSeg = false;
    this.$signalR.$on('new-lance', this.onEventNewLance);

    //console.log(this.loteId);
  },
};
</script>

<style scoped>
.side-counter-item {
  margin: 0 0 10px;
}
.price-table li {
  margin: 0;
}
.nav-lote {
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  align-items: center;
}
.info-leiloeiro h4 {
  font-size: 0.8rem;
  color: rgb(148, 148, 148);
  font-weight: 500;
}

@media (max-width: 385px) {
  .product-bid-form button.custom-button {
    width: 90%;
  }
}

@media (max-width: 430px) {
  .product-bid-form button.custom-button {
    width: 90%;
  }
}

@media (max-width: 323px) {
  .product-bid-form input {
    width: 150px !important;
  }
}

@media (max-width: 575px) {
  .product-bid-form input {
    width: 200px;
  }
}

@media (max-width: 1000px) {
  .nav-lote {
    padding-top: 20px;
  }
}

.btn-navlote {
  width: auto;
  padding: 0;
  font-size: 14px;
  color: #171d1c;
}

.btn-navlote i {
  color: #006ec7;
}

.removido {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  border: solid 4px red;
  color: red;
  padding: 5px 2px;
  border-radius: 5px;
  font-weight: bold;
  letter-spacing: 2px;
}

.box-pulse-yellow {
  border: 1px solid #f9c718;
  box-shadow: 0 0 0 0 rgba(249, 199, 24, 1);
  animation: pulse-yellow 2s infinite;
}

@keyframes pulse-yellow {
  0% {
    box-shadow: 0 0 0 0 rgba(249, 199, 24, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(249, 199, 24, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(249, 199, 24, 0);
  }
}

.box-pulse-red {
  border: 1px solid #e0280f;
  box-shadow: 0 0 0 0 rgba(224, 40, 15, 1);
  animation: pulse-red 1s infinite;
}

@keyframes pulse-red {
  0% {
    box-shadow: 0 0 0 0 rgba(224, 40, 15, 0.7);
  }

  70% {
    box-shadow: 0 0 0 10px rgba(224, 40, 15, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(224, 40, 15, 0);
  }
}

.box-pulse10-red {
  border: 1px solid #e0280f;
  box-shadow: 0 0 0 0 rgba(224, 40, 15, 1);
  animation: pulse-red 0.5s infinite;
}

.btn-warning {
  background-color: #f9c718;
}

.select-numero-lote {
  width: auto;
  height: 30px;
  -moz-border-radius: 19px;
  border-radius: 19px;
  padding: 0;
  padding-left: 10px;
  text-align: center;
  border: none;
  color: #171d1c;
  font-size: 14px;
  font-weight: 600;
}

.small {
  font-size: 10px;
}

.onoffswitch {
  position: relative;
  width: 70px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.onoffswitch-checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  height: 35px;
  padding: 0;
  line-height: 35px;
  border: 2px solid #e3e3e3;
  border-radius: 35px;
  background-color: #f2f2f2;
  transition: background-color 0.3s ease-in;
}
.onoffswitch-label:before {
  content: '';
  display: block;
  width: 35px;
  height: 35px;
  margin: 0px;
  background: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 33px;
  border: 2px solid #e3e3e3;
  border-radius: 35px;
  transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label {
  background-color: #0783ae;
}
.onoffswitch-checkbox:checked + .onoffswitch-label,
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
  border-color: #0783ae;
}
.onoffswitch-checkbox:checked + .onoffswitch-label:before {
  right: 0px;
}

.product-bid-area {
  position: relative;
}

.msg {
  max-width: 277px;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 0 2px 0px black;
  position: absolute;
  z-index: 99999;
  right: 3px;
  color: #625d5d;
  background: #fff;
  font-size: 10px;
  opacity: 1;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  top: -27px;
  left: 10px;
}

.info {
  font-size: 18px;
  padding-left: 5px;
  color: rgb(183, 182, 192);
  cursor: pointer;
}

input,
select {
  height: 50px;
  padding: 0px 15px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  border-radius: 25px;
  border: 1px solid #e0e0f1;
  background: transparent;
  font-size: 16px;
}

.content p {
  margin: 0 !important;
}
</style>
