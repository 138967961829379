<template>
  <div>
    <div class="error-section padding-top padding-bottom bg_img">
      <div class="container">
        <div class="error-wrapper">
          <div class="error-thumb" style="position: relative">
            <img :src="require('@/assets/images/error.png')" alt="error" />
          </div>
          <h4 class="title">
            <a href="/">Página Inicial</a>
          </h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
