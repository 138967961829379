<template>
  <div>
    <banner />
    <search-bar />
    <leiloesDestaque />
    <section class="container-fluid p-0" v-if="banner">
      <div class="banner-fixo">
        <a :href="link_banner" class="w-100"><img class="w-100" :src="banner" /></a>
      </div>
    </section>
    <!-- <LotesDestaque /> -->
    <newsletter />
    <cookie-law theme="base--rounded" buttonText="Aceito" class="cookies" :buttonDecline="true" buttonDeclineText="Ignorar">
      <div slot="message">
        <p>
          Esse site utiliza
          <a href="https://www.kaspersky.com.br/resource-center/definitions/cookies" target="_blank" rel="noopener noreferrer">cookies</a>
          para uma melhor experiência do usuário, fornecer funcionalidades personalizadas e analisar nosso tráfego. Ao continuar você declara o
          aceite.
        </p>
        <p>
          <small>
            Não compartilhamos nenhum dado com terceiros e os mesmos serão utilizados única e exclusivamente para identificação no Leilão.
          </small>
        </p>
        <p>
          <small>
            <a href="/politica-privacidade">Política de Privacidade</a>
          </small>
        </p>
        <p>
          <small>
            <a href="/politica-cookies">Política de cookies</a>
          </small>
        </p>
      </div>
    </cookie-law>
  </div>
</template>

<script>
import SearchBar from '@/components/SearchBar.vue';
import Newsletter from '@/components/Newsletter.vue';
import LeiloesDestaque from './components/LeiloesDestaque.vue';
import Banner from './components/Banner.vue';
import CookieLaw from 'vue-cookie-law';
import { mapActions } from 'vuex';
import { ref } from 'vue';
// import LotesDestaque from './components/LotesDestaque.vue';
export default {
  components: {
    // LotesDestaque,
    LeiloesDestaque,
    Banner,
    CookieLaw,
    SearchBar,
    Newsletter,
  },
  data() {
    return {
      tab: 'aberto',
      fiteredLotes: '',
      searchLoteDestaque: ref(''),
      banner: process.env.VUE_APP_BANNER_HOME,
      link_banner: process.env.VUE_APP_LINK_BANNER_HOME,
    };
  },
  methods: {
    ...mapActions(['setCategorias']),
  },
  async created() {
    await this.setCategorias();
  },
};
</script>
<style scoped>
.cookies a {
  color: #4cb848;
  text-decoration: underline;
}
.banner-fixo {
  -webkit-box-shadow: inset 0px 15px 15px -15px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: inset 0px 15px 15px -15px rgba(0, 0, 0, 0.8);
  box-shadow: inset 0px 15px 15px -15px rgba(0, 0, 0, 0.8);
}
</style>
