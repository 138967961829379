import axios from "axios";
import { ID_URL } from "../../config/config";

const instance = axios.create({
  baseURL: ID_URL,
  timeout: 20000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
});


export default instance;