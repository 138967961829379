import Vue from 'vue';
import VueRouter from 'vue-router';
import Lance from '../views/lance';
import Lotes from '../views/lotes';
import Leiloes from '../views/leiloes/Leiloes.vue';
import Login from '../views/login';
import Cadastro from '../views/cadastro';
import ReenviarConfirmacaoEmail from '../views/login/reenviar-confirmacao-email';
import Dashboard from '../views/dashboard';
import ErrorPage from '../views/error';
import mainOidc from '../service/oidc';
import Home from '../views/home';
import store from '../store';
import middleware from './middleware';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    meta: { requiresAuth: false },
    component: Home,
  },
  {
    path: '/lotes/:leilaoId',
    name: 'lotesId',
    meta: { requiresAuth: false },
    component: Lotes,
    props: true,
  },
  {
    path: '/lotes',
    name: 'lotes',
    meta: { requiresAuth: false },
    component: Lotes,
  },
  {
    path: '/lote/:loteId',
    name: 'Lote',
    meta: { requiresAuth: false },
    component: Lance,
    props: true,
  },
  {
    path: '/pesquisa',
    name: 'pesquisa',
    meta: { requiresAuth: false },
    component: () => import('@/views/lotes/components/LotesPesquisa.vue'),
  },
  {
    path: '/leiloes',
    name: 'leiloes',
    meta: { requiresAuth: false },
    component: Leiloes,
  },
  {
    path: '/login',
    name: 'login',
    meta: { requiresAuth: false },
    component: Login,
  },
  {
    path: '/cadastro',
    name: 'cadastro',
    meta: { requiresAuth: false },
    component: Cadastro,
  },
  {
    path: '/reenviar-confirmacao-email',
    name: 'reenviar-confirmacao-email',
    meta: { requiresAuth: false },
    component: ReenviarConfirmacaoEmail,
  },
  // {
  //   path: '/empresa',
  //   name: 'empresa',
  //   meta: { requiresAuth: false },
  //   component: () => import('@/views/estaticas/empresa.vue'),
  // },
  // {
  //   path: '/entrega',
  //   name: 'entrega',
  //   meta: { requiresAuth: false },
  //   component: () => import('@/views/estaticas/entrega.vue'),
  // },
  // {
  //   path: '/termos-de-uso',
  //   name: 'termodeuso',
  //   meta: { requiresAuth: false },
  //   component: () => import('@/views/estaticas/termodeuso.vue'),
  // },
  // {
  //   path: '/quero-vender',
  //   name: 'querovender',
  //   meta: { requiresAuth: false },
  //   component: () => import('@/views/estaticas/querovender.vue'),
  // },
  // {
  //   path: '/politica-privacidade',
  //   name: 'politicaprivacidade',
  //   meta: { requiresAuth: false },
  //   component: () => import('@/views/estaticas/politicaprivacidade.vue'),
  // },
  // {
  //   path: '/politica-cookies',
  //   name: 'politicacookies',
  //   meta: { requiresAuth: false },
  //   component: () => import('@/views/estaticas/politicacookies.vue'),
  // },
  // {
  //   path: '/politica-de-seguranca',
  //   name: 'politicasegurancainformacao',
  //   meta: { requiresAuth: false },
  //   component: () => import('@/views/estaticas/politicasegurancainformacao.vue'),
  // },
  // {
  //   path: '/como-funciona',
  //   name: 'comofunciona',
  //   meta: { requiresAuth: false },
  //   component: () => import('@/views/estaticas/comofunciona.vue'),
  // },
  {
    path: '/fatura/:faturaToken',
    name: 'Fatura',
    meta: { requiresAuth: false },
    component: () => import('@/views/fatura/index.vue'),
  },
  {
    path: '/conta',
    name: 'Conta',
    meta: { requiresAuth: true },
    component: Dashboard,
    children: [
      {
        path: 'perfil',
        name: 'perfil',
        alias: '/',
        component: () => import('@/views/dashboard/perfil'),
      },
      {
        path: 'edit-perfil',
        name: 'edit-perfil',
        alias: '/',
        component: () => import('@/views/dashboard/edit-perfil'),
      },
      {
        path: 'lotesarrematados',
        name: 'lotesarrematados',
        alias: '/',
        component: () => import('@/views/dashboard/agendamento'),
      },
      {
        path: 'trocar-senha',
        name: 'Trocar Senha',
        alias: '/',
        component: () => import('@/views/dashboard/trocar-senha'),
      },
    ],
  },
  {
    path: '/pagina/:pagina',
    name: 'paginaEstatica',
    meta: { requiresAuth: false },
    component: () => import('@/views/paginaEstatica/index.vue'),
  },
  {
    path: '/validar',
    name: 'validacaodocumento',
    meta: { requiresAuth: false },
    component: () => import('@/views/validacaodocumento/index.vue'),
  },
  {
    path: '/validar/:id',
    name: 'validacaodocumentoid',
    meta: { requiresAuth: false },
    component: () => import('@/views/validacaodocumento/index.vue'),
  },

  {
    path: '*',
    name: 'error',
    component: ErrorPage,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

mainOidc.useRouter(router);

router.beforeEach(async function (to, _, next) {
  if (store.getters.getAuth && !store.getters.getRegisterCompleted) {
    if (to.path.includes('cadastro')) {
      next();
    } else {
      next({ path: '/cadastro' });
    }
  }

  if (to.matched.some((route) => route.meta.requiresAuth)) {
    if (store.getters.getAuth && middleware(store.getters.getAuth.access_token)) {
      next();
    } else {
      next({ path: '/' });
    }
  } else {
    next();
  }
});
export default router;
