/* eslint-disable */
// import axios from "axios"
import http from '../../service/http/http';

const state = {
  paginas: [],
  pagina: null,
  paginasLoading: {
    carregando: false,
  },
  paginaLoading: false,
};

const mutations = {
  SET_PAGINAS: (state, value) => (value ? (state.paginas = value) : (state.paginas = [])),
  SET_PAGINA: (state, value) => (value ? (state.pagina = value) : (state.pagina = null)),
  SET_PAGINAS_LOADING: (state, value) => (value ? (state.paginasLoading.carregando = value) : (state.paginasLoading.carregando = false)),
  SET_PAGINA_LOADING: (state, value) => (value ? (state.paginaLoading = value) : (state.paginaLoading = false)),
};

const getters = {
  getPaginas(state) {
    return state.paginas;
  },
  getPagina(state) {
    return state.pagina;
  },
  getPaginasLoading(state) {
    return state.paginasLoading.carregando;
  },
  getPaginaLoading(state) {
    return state.paginaLoading;
  },
};

const actions = {
  async setPaginas({ commit }, value) {
    return new Promise((resolve, reject) => {
      commit('SET_PAGINAS_LOADING', true);
      return http({
        url: `PaginaEstatica`,
        method: 'get',
      })
        .then((resp) => {
          commit('SET_PAGINAS', resp.data.data);
          commit('SET_PAGINAS_LOADING');
          resolve();
        })
        .catch(() => {
          commit('SET_PAGINAS_LOADING');
          reject();
        });
    });
  },
  async setPagina({ commit }, value) {
    return new Promise((resolve, reject) => {
      commit('SET_PAGINA_LOADING', true);
      return http({
        url: `PaginaEstatica/${value}`,
        method: 'get',
      })
        .then((resp) => {
          commit('SET_PAGINA', resp.data.data);
          commit('SET_PAGINA_LOADING');
          resolve();
        })
        .catch(() => {
          commit('SET_PAGINA_LOADING');
          reject();
        });
    });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
