<template>
  <div class="d-flex justify-content-center mb-5 mt-3">
    <form class="search-form" @submit.prevent="pesquisa">
      <div
        class="combo"
        @click="pesquisarPorShow = !pesquisarPorShow"
        tabindex="0"
      >
        <div class="select-numero-lote">
          <span v-if="!isMobile">POR {{ pesquisarPor }}</span>
          <i
            class="fa fa-keyboard"
            v-if="isMobile && pesquisarPor == 'DESCRIÇÃO'"
          ></i>
          <i
            class="fa fa-hashtag"
            v-if="isMobile && pesquisarPor == 'NÚMERO'"
          ></i>
        </div>
        <ul :class="{ active: pesquisarPorShow }">
          <li
            :class="{ active: pesquisarPor == 'DESCRIÇÃO' }"
            @click="pesquisarPorCampo('DESCRIÇÃO')"
          >
            <input
              type="radio"
              name="pesquisarPor"
              class="form-filtrar"
              value="DESCRIÇÃO"
              v-model="pesquisarPor"
            />Descrição do Lote
          </li>
          <li
            :class="{ active: pesquisarPor == 'NÚMERO' }"
            @click="pesquisarPorCampo('NÚMERO')"
          >
            <input
              type="radio"
              name="pesquisarPor"
              class="form-filtrar"
              value="NÚMERO"
              v-model="pesquisarPor"
            />Número do Lote
          </li>
        </ul>
      </div>
      <input
        :type="pesquisarPor == 'NÚMERO' ? 'number' : 'text'"
        :placeholder="holderTexto"
        class="text-color"
        v-model="searchLoteDestaque"
      />
      <router-link
        :to="{
          name: 'pesquisa',
          query:
            pesquisarPor == 'NÚMERO'
              ? {
                  n: searchLoteDestaque,
                  lid: leilaoId,
                }
              : {
                  t: searchLoteDestaque,
                  lid: leilaoId,
                },
        }"
        ><button type="submit">
          <i
            class="fas fa-search"
            style="color: #171d1c; font-size: 1.5rem"
          ></i>
        </button>
      </router-link>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    leilaoId: String,
  },
  data() {
    return {
      searchLoteDestaque: '',
      pesquisarPorShow: false,
      pesquisarPor: 'DESCRIÇÃO',
      holderTexto: 'PESQUISE POR DESCRIÇÃO DO LOTE',
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    pesquisa() {},
    pesquisarPorCampo(ordem) {
      this.searchLoteDestaque = '';
      this.pesquisarPor = ordem;
      this.holderTexto =
        ordem == 'NÚMERO'
          ? 'PESQUISE POR NÚMERO DO LOTE'
          : 'PESQUISE POR DESCRIÇÃO DO LOTE';
    },
  },
  computed: {
    isMobile() {
      return this.windowWidth < 576;
    },
  },
  async created() {
    const numeroLote = this.$route.query['n'];
    const termo = this.$route.query['t'];

    if (numeroLote && numeroLote > 0) {
      this.pesquisarPor = 'NÚMERO';
      this.holderTexto = 'PESQUISE POR NÚMERO DO LOTE';
      this.searchLoteDestaque = numeroLote;
    } else {
      this.searchLoteDestaque = termo;
    }
  },
};
</script>

<style scoped>
.search-form {
  display: flex;
  max-width: 650px;
}
.search-form input {
  text-align: center;
  border: 4px solid #171d1c;
  background-color: #fff;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 1px solid #171d1c !important;
  z-index: 2;
}

.search-form button {
  z-index: 3;
}

.combo {
  position: relative;
  width: 32%;
}
.select-numero-lote {
  border: 4px solid #171d1c;
  font-weight: 800;
  color: #52606d;
  border-radius: 25px;
  height: 50px;
  background: #e0e0e0;
  background-image: url(/assets/images/chevrons-down.png);
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 11px;
  width: 100%;
  line-height: 46px;
  padding-left: 15px;
  cursor: pointer;
  position: absolute;
  top: 0;
  z-index: 2;
  font-size: 0.9em;
  border-right: 1px solid #171d1c;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.combo ul {
  position: absolute;
  top: 0;
  padding: 50px 0 10px;
  border-radius: 20px;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.4);
  width: 150px;
  text-align: left;
  display: none;
}

.combo ul.active {
  display: block;
  -webkit-animation: fadeIn 500ms;
  animation: fadeIn 500ms;
}

.combo ul li {
  color: #52606d;
  padding: 2px 15px;
  display: block;
  font-size: 11px;
  font-weight: 500;
}

.combo ul li:hover,
.combo ul li.active {
  background-color: #d9d9d973;
  cursor: pointer;
}

.combo label {
  margin: 0;
  padding: 0;
}

.form-filtrar {
  display: inline;
  width: 12px;
  height: 12px;
  margin-right: 15px;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 575px) {
  .search-form {
    width: 90% !important;
  }

  .search-form input {
    font-size: 11px;
    padding: 0 45px 0 5px;
  }
}
</style>
