<template>
  <div class="tipo-lote badge" v-if="tipoLote">
    <div class="tipo-lote-card">{{ tipoLote.descricao }}</div>
  </div>
</template>

<script>
export default {
  props: {
    tipoLote: {
      type: Object,
      required: false,
    },
  },
};
</script>

<style>
.tipo-lote {
  display: flex;
  justify-content: flex-end;
  background-color: #d9d9d9;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.36);
}
.tipo-lote-card {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  line-height: 1.6em;
  display: flex;
  justify-content: center;
  color: #171d1c;
  align-items: center;
  height: 35px;
  text-align: center;
  border-radius: 15px;
  padding: 0 5px;
  margin: 0 0;
  text-transform: uppercase;
}
</style>
