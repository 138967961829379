<template>
  <div :class="classeRaiz">
    <div class="auction-item-2">
      <div class="auction-thumb">
        <vue-skeleton-loader
          class="border-radius-5"
          type="rect"
          animation="fade"
          width="100%"
          height="100%"
        />
      </div>
      <div class="auction-content" style="padding-bottom: 10px">
        <vue-skeleton-loader
          class="mb-2 mt-2"
          type="rect"
          animation="fade"
          width="80%"
          height="20px"
          style="margin: 0 auto"
        />
        <vue-skeleton-loader
          class="mb-2"
          type="rect"
          animation="fade"
          width="70%"
          height="18px"
          style="margin: 0 auto"
        />
        <vue-skeleton-loader
          class="mb-2 mt-4"
          type="rect"
          animation="fade"
          width="50%"
          height="15px"
          style="margin: 0 auto"
        />
        <vue-skeleton-loader
          class="mb-2"
          type="rect"
          animation="fade"
          width="50%"
          height="15px"
          style="margin: 0 auto"
        />
        <vue-skeleton-loader
          class="mb-2"
          type="rect"
          animation="fade"
          width="50%"
          height="15px"
          style="margin: 0 auto"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue';
export default {
  props: {
    classeRaiz: {
      type: String,
    },
  },
  components: {
    VueSkeletonLoader,
  },
};
</script>

<style>
.center {
  display: flex;
  justify-content: center;
}
</style>
