<template>
  <div class="newslater-wrapper">
    <div class="container">
      <div class="newslater-area">
        <div class="newslater-thumb">
          <img
            :src="require('@/assets/images/footer/newslater.png')"
            alt="footer"
            width="285"
          />
          <!-- <lottie-animation path="@/assets/images/mail.json" /> -->
        </div>
        <div class="newslater-content">
          <div class="section-header left-style mb-low">
            <h5 class="cate">Inscreva-se</h5>
            <h3 class="title">Para obter benefícios exclusivos</h3>
          </div>
          <form class="subscribe-form" @submit.prevent="increver">
            <input
              type="email"
              placeholder="Seu melhor email"
              name="email"
              v-model="email"
              required
            />
            <button type="submit" class="custom-button news-button">
              Enviar
            </button>
          </form>
          <div
            class="alert alert-danger alert-dismissible fade show d-flex align-items-center"
            role="alert"
            style="margin-top: 20px; padding: 0%"
            v-if="messageError"
          >
            <strong class="col-10">{{ messageError }}</strong>
            <button
              type="button"
              class="close col-2 d-flex align-items-center"
              id="close-button"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true ">&times;</span>
            </button>
          </div>
          <div
            class="alert alert-success alert-dismissible fade show d-flex align-items-center"
            role="alert"
            style="margin-top: 20px; padding: 0%"
            v-if="messageSucess"
          >
            <strong class="col-10">{{ messageSucess }}</strong>
            <button
              type="button"
              class="close col-2 d-flex align-items-center"
              id="close-button"
              data-dismiss="alert"
              aria-label="Close"
            >
              <span aria-hidden="true ">&times;</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from '@/service/http/http';

export default {
  data() {
    return {
      email: '',
      messageError: '',
      messageSucess: '',
      colorPrimary: process.env.VUE_APP_PRIMARY_COLOR,
      colorConstrast: process.env.VUE_APP_CONSTRAST_COLOR,
    };
  },
  methods: {
    increver() {
      const payload = {
        email: this.email,
      };
      return http({
        url: `newsletter`,
        method: 'post',
        data: payload,
      })
        .then(() => {
          this.messageSucess = 'Inscrito com sucesso';
          setTimeout(() => {
            this.email = '';
            this.messageSucess = '';
          }, 3000);
        })
        .catch(() => {
          this.messageError = 'Não foi possivel realizar a inscrição';
          setTimeout(() => {
            this.email = '';
            this.messageError = '';
          }, 3000);
        });
    },
  },
};
</script>

<style>
.news-button {
  background-color: v-bind(colorPrimary) !important;
  color: v-bind(colorConstrast) !important;
}
</style>
