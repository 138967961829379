<template>
  <div class="product-single-sidebar mb-3">  
    <div style="display: flex; justify-content: center;flex-direction: column;align-items: center;row-gap: 10px;padding-bottom: 10px;">
     
        <vue-skeleton-loader
          type="rect"
          animation="fade"
          :width="100"
          :height="20.5"
        />
    
      <vue-skeleton-loader
        type="rect"
        animation="fade"
        :width="200"
        :height="30"
      />
    </div>
    <div class="side-counter-area">
      <div class="side-counter-item">
        <div class="thumb">
          <img
            :src="require('@/assets/images/product/icon1.png')"
            alt="product"
          />
        </div>
        <div class="content">
          <h3 class="count-title">
            <vue-skeleton-loader
              type="rect"
              animation="fade"
              :width="100"
              :height="46"
            />
          </h3>
          <p>Participantes</p>
        </div>
      </div>
      <div class="side-counter-item">
        <div class="thumb">
          <img
            :src="require('@/assets/images/product/icon2.png')"
            alt="product"
          />
        </div>
        <div class="content">
          <h3 class="count-title">
            <vue-skeleton-loader
              type="rect"
              animation="fade"
              :width="100"
              :height="46"
            />
          </h3>
          <p>Visualizações</p>
        </div>
      </div>
      <div class="side-counter-item">
        <div class="thumb">
          <img
            :src="require('@/assets/images/product/icon3.png')"
            alt="product"
          />
        </div>
        <div class="content">
          <h3 class="count-title">
            <vue-skeleton-loader
              type="rect"
              animation="fade"
              :width="100"
              :height="46"
            />
          </h3>
          <p>Lances</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSkeletonLoader from "skeleton-loader-vue";
export default {
  components: {
    VueSkeletonLoader,
  },
};
</script>

<style>
</style>