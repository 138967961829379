import Vue from 'vue'
import { createOidcAuth, SignInType } from 'vue-oidc-client/vue2';
import store from "@/store"
import { ID_URL, APP_URL } from "@/config/config.js"
import jwt from "jsonwebtoken";

const mainOidc = createOidcAuth('main', SignInType.Popup, APP_URL, {
  authority: ID_URL,
  client_id: 'site-eblonline',
  response_type: 'code',
  scope: 'openid profile leilao.api',
  prompt: 'login',
  automaticSilentRenew: true
})

mainOidc.events.addUserLoaded(async function (user) {
  await mainOidc.userProfile
  const { full_name, email, email_confirmed, uid } = await jwt.decode(
    user.access_token
  );
  const auth = { ...user, full_name, email, email_confirmed, uid };
  await store.commit('SET_AUTH', auth)
});

// mainOidc.events.addAccessTokenExpiring(function () {
//   // eslint-disable-next-line no-console
//   console.log('access token expiring')
// })

// mainOidc.events.addAccessTokenExpired(function () {
//   // eslint-disable-next-line no-console
//   console.log('access token expired')
// })


// mainOidc.events.addUserUnloaded(function () {
//   // eslint-disable-next-line no-console
//   console.log('user unloaded')
// })

// mainOidc.events.addUserSignedOut(function () {
//   // eslint-disable-next-line no-console
//   console.log('user signed out')
//   // router.push({ name: "index" })
// })

// mainOidc.events.addUserSessionChanged(function () {
//   // eslint-disable-next-line no-console
//   console.log('user session changed')
// })

// a little something extra
Vue.prototype.$oidc = mainOidc

export default mainOidc;