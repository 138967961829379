import Vue from 'vue';
import VueMask from 'v-mask';
import App from './App.vue';
import router from './router';
import store from './store';
import mainOidc from '@/service/oidc';
import Vue2Filters from 'vue2-filters';
import SignalR from './plugins/signalR';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
// import "/assets/css/bootstrap.min.css"
// import * as Sentry from '@sentry/vue';
// import { BrowserTracing } from '@sentry/tracing';
import VCalendar from 'v-calendar';

Vue.config.productionTip = false;

Vue.use(VueMask);
Vue.use(Vue2Filters);
Vue.use(SignalR);
Vue.use(VueSweetalert2);

Vue.use(VCalendar);

document.title = process.env.VUE_APP_SITE_NAME;
document.querySelector("link[rel~='icon']").href = process.env.VUE_APP_FAVICON;

// if (process.env.NODE_ENV == 'production') {
//   var script = document.createElement('script');
//   script.src = '//code.jivosite.com/widget/XX7QOys9aV';
//   script.async = true;
//   document.body.appendChild(script);
// }

mainOidc.startup().then((ok) => {
  if (ok) {
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount('#app');
  }
});
