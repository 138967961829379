var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"car-auction-section pos-rel oh"},[_c('Banner'),_c('div',{staticClass:"container mb-5"},[_c('div',[_vm._m(0),_c('div',{staticClass:"filter-buttons d-flex justify-content-center mb-4 mt-4 w-100 flex-wrap"},[_c('button',{staticClass:"col-sm-11 col-md-5 col-lg-3 m-2 buttons-filter",class:{ activeButtons: _vm.abertos },on:{"click":function($event){_vm.agendados = false;
            _vm.encerrados = false;
            _vm.abertos = true;}}},[(_vm.abertos)?_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/patioRocha/abertos.png")}}):_vm._e(),(!_vm.abertos)?_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/patioRocha/abertosInactive.svg")}}):_vm._e(),_vm._v(" Abertos ")]),_c('button',{staticClass:"col-sm-11 col-md-5 col-lg-3 m-2 buttons-filter",class:{ activeButtons: _vm.agendados },on:{"click":function($event){_vm.agendados = true;
            _vm.encerrados = false;
            _vm.abertos = false;}}},[(_vm.agendados)?_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/patioRocha/agendados.svg")}}):_vm._e(),(!_vm.agendados)?_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/patioRocha/relogio.png")}}):_vm._e(),_vm._v(" Agendados ")]),_c('button',{staticClass:"col-sm-11 col-md-5 col-lg-3 m-2 buttons-filter",class:{ activeButtons: _vm.encerrados },on:{"click":function($event){_vm.agendados = false;
            _vm.encerrados = true;
            _vm.abertos = false;}}},[(_vm.encerrados)?_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/patioRocha/encerradosActive.svg")}}):_vm._e(),(!_vm.encerrados)?_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/images/patioRocha/close.png")}}):_vm._e(),_vm._v(" Encerrados ")])])]),(_vm.abertos)?_c('LeiloesEmAberto'):_vm._e(),(_vm.encerrados)?_c('LeiloesFechado'):_vm._e(),(_vm.agendados)?_c('LeiloesEmBreve'):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left d-block"},[_c('h2',{staticClass:"title mb-3"},[_vm._v("Leilões Disponíveis")]),_c('p',[_vm._v("Veja os todos os leilões na plataforma")])])
}]

export { render, staticRenderFns }