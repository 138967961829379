/* eslint-disable */
import http from "../../service/http/http";
const ordens = [{ "descricao": "Titulo", "ordem": "titulo" }, { "descricao": "Maior Preço", "ordem": "maior_preco" }, { "descricao": "Menor Preço", "ordem": "menor_preco" }, { "descricao": "Mais Lances", "ordem": "mais_lances" }, { "descricao": "Menos Lances", "ordem": "menos_lances" }, { "descricao": "Mais Visitados", "ordem": "mais_visitas" }]
const state = {
  categorias: [],
  categoriasSelecionadas: [],
  ordens: ordens,
  preco: [],
};

const mutations = {
  SET_CATEGORIAS: (state, value) =>
    value ? (state.categorias = value) : (state.categorias = []),
  SET_CATEGORIAS_SELECIONADAS: (state) => {
    let selecionados = [];
    state.categorias.forEach((c) => {
      if (c["subcategorias"] && c.subcategorias.length > 0) {
        c.subcategorias.forEach((sub) => {
          if (sub.selected) selecionados.push(sub.categoriaId);
        });
      } else {
        if (c.selected) selecionados.push(c.categoriaId);
      }
    });

    state.categoriasSelecionadas = selecionados;
  },
  SET_ORDENS: (state, value) =>
    value ? (state.ordens = value) : (state.ordens = []),
  SET_PRECO: (state, value) =>
    value ? (state.preco = value) : (state.preco = []),
};

const getters = {
  getCategorias: (state) => state.categorias,
  getCategoriasSelecionadas: (state) => state.categoriasSelecionadas,
  getOrdens: (state) => state.ordens,
  getPreco: (state) => state.preco,
};

const actions = {
  setOrdens({ commit }) {
    // http
    //   .get("OrdemLotes")
    //   .then((response) => {
    //     commit("SET_ORDENS", response.data.data);
    //   })
    //   .catch((error) => console.error(error));
  },
  setCategorias({ commit, state }) {
    if (state.categorias.length > 0) return
    return http
      .get("Categoria")
      .then(async (response) => {
        const { data } = response.data;
        let categorias = [];
        data.forEach((categoria, index, arr) => {
          if (categoria.categoriaPaiId == null) {
            categoria["selected"] = false;
            let subcategorias = arr
              .filter((filho) => filho.categoriaPaiId == categoria.categoriaId)
              .map((m) => {
                const obj = Object.assign({}, m);
                obj["selected"] = false;
                return obj;
              });
            categoria["subcategorias"] = subcategorias;
            categorias.push(categoria);
          }
        });
        commit("SET_CATEGORIAS", categorias);
      })
      .catch((error) => console.error("categoria: ", error));
  },
  checkCategorias({ commit, state }, value) {
    let categorias = [...state.categorias];
    categorias.forEach((item) =>
      item.subcategorias.forEach((sub) => {
        if (sub.categoriaPaiId === value) {
          const obj = Object.assign({}, item);
          sub.selected = obj.selected;
        }
      })
    );

    commit("SET_CATEGORIAS", categorias);
    commit("SET_CATEGORIAS_SELECIONADAS");
  },

  checkSubCategoria({ commit, state }) {
    let categorias = [...state.categorias];

    commit("SET_CATEGORIAS", categorias);
    commit("SET_CATEGORIAS_SELECIONADAS");
  },

  setPreco({ commit }, value) {
    commit("SET_PRECO", value);
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
