// import axios from "axios"
import router from '../../router';
import httpId from '@/service/http/http-id.js';
import http from '@/service/http/http.js';
import mainOidc from '../../service/oidc';
import jwt from 'jsonwebtoken';
import store from '@/store';

const state = {
  auth: null,
  tokenApi: null,
  loginLoading: false,
  loginError: '',
  registerCompleted: false,
};

const mutations = {
  SET_AUTH: (state, value) =>
    value ? (state.auth = value) : (state.auth = null),
  SET_LOGIN_LOADING: (state, value) =>
    value ? (state.loginLoading = value) : (state.loginLoading = false),
  SET_LOGIN_ERROR: (state, value) =>
    value ? (state.loginError = value) : (state.loginError = ''),
  SET_REGISTER_COMPLETED: (state, value) =>
    value
      ? (state.registerCompleted = value)
      : (state.registerCompleted = false),
  SET_TOKEN_API: (state, value) =>
    value ? (state.tokenApi = value) : (state.tokenApi = false),
};

const getters = {
  getAuth: (state) => state.auth,
  getLoginLoading: (state) => state.loginLoading,
  getLoginError: (state) => state.loginError,
  getNomeUsuarioLogado: (state) =>
    state.auth
      ? state.auth.profile
        ? state.auth.profile.full_name.split(' ')[0]
        : state.auth.full_name.split(' ')[0]
      : '',
  getFotoUsuarioLogado: (state) =>
    state.auth
      ? state.auth.profile
        ? Array.isArray(state.auth.profile.picture)
          ? state.auth.profile.picture[state.auth.profile.picture.length - 1]
          : state.auth.profile.picture
        : ''
      : '',
  getRegisterCompleted: (state) => state.registerCompleted,
};

const actions = {
  async setLoginGoogle() {
    await mainOidc.signIn({ acr_values: 'idp:Google' });

    return;
  },
  async setLoginFacebook({ dispatch }) {
    await mainOidc.signIn({ acr_values: 'idp:Facebook' });
    await dispatch('verificaAtivo');
    return;
  },
  async logout({ commit }) {
    if (
      store.state.login.auth.profile?.idp === 'Google' ||
      store.state.login.auth.profile?.idp === 'Facebook'
    ) {
      commit('SET_AUTH');

      await mainOidc.signOut();
    } else {
      commit('SET_AUTH');
      router.push({ name: 'index' }).catch(() => {});
    }
  },

  async verificaAtivo({ commit }) {
    return new Promise((resolve, reject) => {
      http({
        url: `usuario/info`,
        method: 'get',
      })
        .then((resp) => {
          if (!resp.data.data.ativo && resp.data.data.cadastroCompleto) {
            commit('SET_AUTH');
            router.push({ name: 'login', params: { inativo: '' } });
          }
          resolve();
        })
        .catch(() => {
          reject();
        });
    });
  },

  setToken({ commit }) {
    const data = new URLSearchParams();
    data.append('client_id', 'site-eblonline');
    data.append('client_secret', '8FM48ML0gL8ulcME9uGk36uPgmQDGJJx');
    data.append('grant_type', 'client_credentials');
    return httpId
      .post('connect/token', data)
      .then((response) => {
        commit('SET_TOKEN_API', response.data);
      })
      .catch(() => {});
  },

  async setLogin({ commit, dispatch }, value) {
    commit('SET_LOGIN_LOADING', true);
    const data = new URLSearchParams();
    data.append('client_id', 'site-eblonline');
    data.append('client_secret', '8FM48ML0gL8ulcME9uGk36uPgmQDGJJx');
    data.append('grant_type', 'password');
    data.append('scope', 'openid profile leilao.api');
    data.append('username', value.nomeUsuario);
    data.append('password', value.senha);
    return new Promise((resolve, reject) => {
      httpId
        .post('connect/token', data)
        .then(async (response) => {
          const { full_name, email, email_confirmed, uid } = await jwt.decode(
            response.data.access_token
          );
          const auth = {
            ...response.data,
            full_name,
            email,
            email_confirmed,
            uid,
          };

          dispatch('verificaAtivo');

          commit('SET_AUTH', auth);
          commit('SET_LOGIN_ERROR', '');
          commit('SET_MENSAGEM', '');
          commit('SET_REGISTER_COMPLETED', true);
          // router.push(value.returnUrl || { name: "index" });
          commit('SET_LOGIN_LOADING');
          resolve();
        })
        .catch(function (error) {
          commit('SET_LOGIN_LOADING');
          if (
            error.response.data &&
            error.response.data['error_detail'] === 'email_not_confirmed'
          ) {
            commit('SET_LOGIN_ERROR', 'Email não confirmado');
            return;
          } else if (
            error.response.data &&
            error.response.data.error_description ===
              'invalid_username_or_password'
          ) {
            commit('SET_LOGIN_ERROR', 'Usuário ou Senha inválidos');
            return;
          }
          commit('SET_LOGIN_ERROR', error.response.data.error_description);
          reject();
        });
    });
  },

  refreshToken({ commit, state }, value) {
    //console.log(value);
    const data = new URLSearchParams();
    data.append('client_id', 'site-eblonline');
    data.append('client_secret', '8FM48ML0gL8ulcME9uGk36uPgmQDGJJx');
    data.append('grant_type', 'refresh_token');
    data.append('refresh_token', value);

    httpId.post('connect/token', data).then(async (response) => {
      const auth = {
        ...state.auth,
        access_token: response.data.access_token,
      };
      commit('SET_AUTH', auth);
    });
  },
  checkRegisterCompleted({ commit }) {
    return new Promise((resolve, reject) => {
      http({
        url: `usuario/info`,
        method: 'get',
      })
        .then((resp) => {
          if (!resp.data.data.ativo && resp.data.data.cadastroCompleto) {
            commit('SET_AUTH');
          }
          commit('SET_USUARIO_INFO', resp.data.data);
          commit('SET_REGISTER_COMPLETED', resp.data.data.cadastroCompleto);
          resolve(resp.data.data);
        })
        .catch(() => reject(false));
    });
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
