<template>
  <div>
    <div class="content">
      <div class="center" v-if="getLotesLoading">
        <vue-skeleton-loader type="rect" animation="fade" :width="267" :height="200" class="d-none d-sm-block slide-loader" />
        <vue-skeleton-loader type="rect" animation="fade" :width="277" :height="200" class="d-none d-sm-block slide-loader" />
        <vue-skeleton-loader type="rect" animation="fade" :width="277" :height="200" class="d-none d-sm-block slide-loader" />
        <vue-skeleton-loader type="rect" animation="fade" :width="277" :height="200" class="d-none d-sm-block slide-loader" />
        <vue-skeleton-loader type="rect" animation="fade" :width="277" :height="213" class="d-block d-sm-none" />
      </div>
      <carousel
        v-else
        :per-page="1.2"
        :perPageCustom="[
          [576, 2],
          [768, 3],
          [992, 4],
        ]"
        :navigationEnabled="mobile ? false : true"
        :mouse-drag="true"
        :touchDrag="true"
        :scrollPerPage="false"
        :paginationEnabled="false"
        navigationNextLabel="<img src='/assets/images/chevron_right.png'>"
        navigationPrevLabel="<img src='/assets/images/chevron_left.png'>"
        :minSwipeDistance="15"
        :center-mode="true"
      >
        <slide class="slide" v-for="(foto, index) in fotosVisiveis" :key="foto.arquivoId" :data-index="index" @slideclick="slideClick">
          <img class="img" :src="foto.arquivo.url" />
        </slide>
      </carousel>
    </div>
    <a id="mostrarModal" v-show="false" data-target="#slideView" data-toggle="modal"> </a>
    <vue-gallery-slideshow :images="imagens" :index="index" @close="index = null"></vue-gallery-slideshow>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Carousel, Slide } from 'vue-carousel';
import VueSkeletonLoader from 'skeleton-loader-vue';
import VueGallerySlideshow from 'vue-gallery-slideshow';
export default {
  name: 'SlideView',
  components: {
    Carousel,
    Slide,
    VueSkeletonLoader,
    VueGallerySlideshow,
  },
  data: () => ({
    windowWidth: window.innerWidth,
    index: null,
  }),
  computed: {
    ...mapGetters(['getLotesLoading', 'getLote']),
    fotosVisiveis() {
      return this.getLote && this.getLote.fotos.length > 0 ? this.getLote.fotos.filter((x) => x.tipoFoto.visivelSite) : [];
    },
    imagens() {
      return this.getLote && this.getLote.fotos.length > 0 ? this.getLote.fotos.filter((x) => x.tipoFoto.visivelSite).map((e) => e.arquivo.url) : [];
    },
    mobile() {
      return this.windowWidth < 600;
    },
  },
  methods: {
    slideClick(slide) {
      this.index = Number(slide.index);
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize);
  },
};
</script>

<style scoped>
.slide-loader {
  margin: 10px;
  border-radius: 30px !important;
}
.slide {
  color: #999;
  cursor: pointer;
  height: 225px;
}
.img {
  width: 100%;
  padding: 10px;
  border-radius: 30px;
  object-fit: cover;
}

.modal-body {
  padding: 0;
}
</style>
