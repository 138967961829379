// import { mapGetters } from "vuex";
import axios from "axios";
import jwt from 'jsonwebtoken'
import store from "../../store";
import { API_URL } from "../../config/config";

const instance = axios.create({
  baseURL: API_URL,
  timeout: 100000
});


instance.interceptors.request.use(async function (config) {
  const auth = store.state.login.auth
  const token = store.state.login.tokenApi

  let access_token = null;

  if (auth) {
    try {
      const decoded = await jwt.decode(auth.access_token)
      if (auth.profile && auth.profile.idp) {
        if (Date.now() >= decoded.exp * 1000) {
          store.commit('SET_AUTH')
          await store.dispatch('setToken')
          return
        }
      }

      if (auth.refresh_token && Date.now() >= decoded.exp * 1000) {
        try {
          await store.dispatch('refreshToken', auth.refresh_token);
          return;
        } catch (_) {
          //store.dispatch('logout')
        }
      }

      if (Date.now() >= decoded.exp * 1000) {
        await store.dispatch('logout');
        return;
      }

      access_token = auth.access_token
    } catch (_) {
      // console.log("refresh_token_error:", error)
    }
  }
  else {
    if (!token) {
      await store.dispatch('setToken')
      access_token = store.state.login.tokenApi.access_token
    }

    try {
      const decoded = await jwt.decode(store.state.login.tokenApi.access_token)
      if (Date.now() >= decoded.exp * 1000) {
        await store.dispatch('setToken')
      }

      access_token = store.state.login.tokenApi.access_token
    } catch (error) {
      await store.dispatch('setToken')
    }
  }

  config.headers.Authorization = "Bearer " + access_token;

  return config;
});



export default instance;