import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";

import leiloes from '@/store/modules/leiloes.js'
import lote from './modules/lote'
import login from './modules/login'
import cadastro from './modules/cadastro'
import categoria from './modules/categoria'
import lance from './modules/lance'
import agendamento from './modules/agendamento'
import paginas from './modules/paginas'
Vue.use(Vuex)

const dataState = createPersistedState({
  paths: ['login', 'categoria']
})

export default new Vuex.Store({
  plugins: [dataState],
  modules: {
    leiloes,
    lote,
    login,
    cadastro,
    categoria,
    lance,
    agendamento,
    paginas
  }
})
