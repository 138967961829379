import http from "../../service/http/http"
import router from "../../router"
import store from "@/store"
import axios from "axios";

const state = {
  cadastroLoading: false,
  cadastroErrors: [],
  mensagem: "",
  usuarioInfo: "",
  loadingCep: false
}

const mutations = {
  SET_CADASTRO_LOADING: (state, value) => value ? state.cadastroLoading = value : state.cadastroLoading = false,
  SET_CADASTRO_ERROR: (state, value) => value ? state.cadastroErrors = value : state.cadastroErrors = [],
  SET_MENSAGEM: (state, value) => value ? state.mensagem = value : state.mensagem = "",
  SET_USUARIO_INFO: (state, value) => value ? state.usuarioInfo = value : state.usuarioInfo = "",
  SET_LOADING_CEP: (state, value) => value ? state.loadingCep = value : state.loadingCep = false,
}

const getters = {
  getCadastroLoading: state => state.cadastroLoading,
  getCadastroError: state => state.cadastroErrors,
  getMensagem: state => state.mensagem,
  getUsuarioInfo: state => state.usuarioInfo,
  getLoadingCep: state => state.loadingCep,
}

const actions = {
  async setCadastro({ commit, dispatch }, value) {
    commit('SET_CADASTRO_LOADING', true)
    return new Promise((resolve, reject) => {
      http.post("usuario/registrar", value)
        .then(async () => {
          commit('SET_CADASTRO_LOADING')
          commit('SET_CADASTRO_ERROR')
          if (store.state.login.auth?.profile.idp !== 'Google' && store.state.login.auth?.profile.idp !== 'Facebook') {
            commit('SET_MENSAGEM', "Foi enviado um email com link para confirmar seu cadastro.")
            router.push({ name: 'login' })
          } else {
            await dispatch('checkRegisterCompleted');

          }
          resolve();
        })
        .catch(function (error) {
          commit('SET_CADASTRO_LOADING')

          const result = [];
          const errors = error.response.data.Errors;
          for (var key in errors) {
            result.push(errors[key])
          }
          commit('SET_CADASTRO_ERROR', result)
          reject();
        });

    })
  },

  async info({ commit }) {
    return new Promise((resolve, reject) => {
      commit('SET_CADASTRO_LOADING', true)
      http.get("usuario/info")
        .then((resp) => {
          commit('SET_CADASTRO_LOADING')
          commit('SET_CADASTRO_ERROR')
          commit('SET_USUARIO_INFO', resp.data.data)
          resolve()
        })
        .catch(function (error) {
          commit('SET_CADASTRO_LOADING')
          commit('SET_CADASTRO_ERROR', error.response.data)
          reject()
        });

    })
  },
  async loadCep({ commit }, value) {
    commit('SET_LOADING_CEP', true)

    const zip_code = value.trim().replace(/[^0-9]/g, '');

    const url_cep = 'https://viacep.com.br/ws/' + zip_code + '/json';

    // clear all headers axios to viacep
    axios.defaults.headers.common = null;

    return axios.get(url_cep)
      .then((response) => {
        commit('SET_LOADING_CEP', false);
        return response.data;
      })
      .catch(() => {
        commit('SET_LOADING_CEP', false)
        commit('mensagem', 'CEP não encontrado')
      });
  }

}

export default {
  state,
  mutations,
  getters,
  actions
};