<template>
  <section class="car-auction-section pos-rel oh">
    <Banner />
    <div class="container mb-5">
      <div>
        <div class="left d-block">
          <h2 class="title mb-3">Leilões Disponíveis</h2>
          <p>Veja os todos os leilões na plataforma</p>
        </div>
        <div
          class="filter-buttons d-flex justify-content-center mb-4 mt-4 w-100 flex-wrap"
        >
          <button
            class="col-sm-11 col-md-5 col-lg-3 m-2 buttons-filter"
            :class="{ activeButtons: abertos }"
            @click="
              agendados = false;
              encerrados = false;
              abertos = true;
            "
          >
            <img
              src="@/assets/images/patioRocha/abertos.png"
              class="mr-2"
              v-if="abertos"
            />
            <img
              src="@/assets/images/patioRocha/abertosInactive.svg"
              class="mr-2"
              v-if="!abertos"
            />
            Abertos
          </button>
          <button
            class="col-sm-11 col-md-5 col-lg-3 m-2 buttons-filter"
            :class="{ activeButtons: agendados }"
            @click="
              agendados = true;
              encerrados = false;
              abertos = false;
            "
          >
            <img
              src="@/assets/images/patioRocha/agendados.svg"
              class="mr-2"
              v-if="agendados"
            />
            <img
              src="@/assets/images/patioRocha/relogio.png"
              class="mr-2"
              v-if="!agendados"
            />
            Agendados
          </button>
          <button
            class="col-sm-11 col-md-5 col-lg-3 m-2 buttons-filter"
            :class="{ activeButtons: encerrados }"
            @click="
              agendados = false;
              encerrados = true;
              abertos = false;
            "
          >
            <img
              src="@/assets/images/patioRocha/encerradosActive.svg"
              class="mr-2"
              v-if="encerrados"
            />
            <img
              src="@/assets/images/patioRocha/close.png"
              class="mr-2"
              v-if="!encerrados"
            />
            Encerrados
          </button>
        </div>
      </div>
      <LeiloesEmAberto v-if="abertos" />
      <LeiloesFechado v-if="encerrados" />
      <LeiloesEmBreve v-if="agendados" />
    </div>
  </section>
</template>

<script>
import LeiloesEmAberto from './components/LeiloesEmAberto.vue';
import LeiloesEmBreve from './components/LeiloesEmBreve.vue';
import LeiloesFechado from './components/LeiloesFechado.vue';
import Banner from '@/views/home/components/Banner.vue';
export default {
  components: {
    Banner,
    LeiloesEmAberto,
    LeiloesEmBreve,
    LeiloesFechado,
  },
  data() {
    return {
      agendados: false,
      encerrados: false,
      abertos: true,
      colorPrimary: process.env.VUE_APP_PRIMARY_COLOR,
      colorSecondary: process.env.VUE_APP_SECONDARY_COLOR,
      colorConstrast: process.env.VUE_APP_CONSTRAST_COLOR,
    };
  },
};
</script>

<style scoped>
.verTodos {
  color: #171d1c;
  cursor: pointer;
}

h4 {
  font-size: 1rem;
}

.buttons-filter {
  border-radius: 13px;
  border: 1px solid #ddd;
  color: v-bind(colorPrimary);
}

.activeButtons {
  background-color: v-bind(colorPrimary);
  color: v-bind(colorConstrast);
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
