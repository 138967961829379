<template>
  <div>
    <section class="account-section padding-bottom padding-top">
      <div class="container">
        <div class="account-wrapper">
          <div class="left-side">
            <div class="section-header">
              <h2 class="title">Cadastre-se</h2>
              <p class="title red" v-if="getAuth && !getRegisterCompleted">
                Você se autenticou pelo <b>{{ getAuth.profile.idp }}</b
                >, <u> complete seu cadastro</u> abaixo.
              </p>
            </div>
            <!--<ul class="login-with">
              <li>
                <a href="javascript:void(0)" class="facebook"
                  ><i class="fab fa-facebook"></i>Entrar com Facebook</a
                >
              </li>
              <li>
                <a href="javascript:void(0)" class="google"
                  ><i class="fab fa-google-plus"></i>Entrar com Google</a
                >
              </li>
            </ul>
            <div class="or">
              <span>Ou</span>
            </div>-->
            <form class="login-form" @submit.prevent="cadastro">
              <div class="row justify-content-center">
                <div class="col-12">
                  <p class="title"><i class="far fa-address-card"></i> Dados Pessoais</p>
                  <hr />
                </div>

                <div class="form-group mb-30 col-12">
                  <label for="nome-completo"><i class="fas fa-user"></i></label>
                  <input type="text" id="nome-completo" placeholder="Nome completo" v-model="nomeCompleto" required />
                </div>

                <div class="form-group mb-30 col-12 col-md-6">
                  <label for="data-nascimento"><i class="fas fa-calendar-alt"></i></label>
                  <input type="text" id="data-nascimento" placeholder="Data nascimento" v-model="dataNascimento" v-mask="'##/##/####'" required />
                </div>

                <div class="form-group mb-30 col-12 col-md-6">
                  <label for="genero"><i class="fas fa-venus-mars"></i></label>
                  <select id="genero" v-model="genero" required>
                    <option value="">Gênero</option>
                    <option value="F">Feminino</option>
                    <option value="M">Masculino</option>
                    <option value="O">Outro</option>
                  </select>
                </div>
                <div class="form-group mb-30 col-12 col-md-6">
                  <label for="telefone"><i class="fas fa-phone-alt"></i></label>
                  <input type="text" id="telefone" placeholder="Telefone" v-model="telefone" v-mask="'(##) #####-####'" required />
                </div>
                <div class="form-group mb-30 col-12 col-md-6">
                  <label for="telefone-conf"><i class="fas fa-phone-alt"></i></label>
                  <input
                    type="text"
                    id="telefone-conf"
                    placeholder="Confirmação de Telefone"
                    v-model="telefoneconf"
                    v-mask="'(##) #####-####'"
                    required
                  />
                </div>
                <div class="form-group mb-30 col-12 col-md-6">
                  <label for="tipoPessoa"><i class="fas fa-users-cog"></i></label>
                  <select id="tipoPessoa" v-model="tipoPessoa" required>
                    <option value="PF">Pessoa Física</option>
                    <option value="PJ">Pessoa Juridica</option>
                  </select>
                </div>
                <div class="form-group mb-30 col-12 col-md-6">
                  <label for="cpf"><i class="fas fa-id-card"></i></label>
                  <input
                    v-if="tipoPessoa == 'PF'"
                    type="text"
                    id="cpf"
                    placeholder="CPF"
                    v-model="numeroDocumento"
                    v-mask="'###.###.###-##'"
                    required
                  />
                  <input
                    v-if="tipoPessoa == 'PJ'"
                    type="text"
                    id="cpf"
                    placeholder="CNPJ"
                    v-model="numeroDocumento"
                    v-mask="'##.###.###/####-##'"
                    required
                  />
                </div>
                <div class="form-group mb-30 col-12 col-md-4">
                  <label for="rg"><i class="fas fa-id-card"></i></label>
                  <input type="text" id="rg" placeholder="RG" v-model="rg" required />
                </div>
                <div class="form-group mb-30 col-12 col-md-4">
                  <label for="orgao-emissor"><i class="fas fa-users-cog"></i></label>
                  <input type="text" id="orgao-emissor" placeholder="Orgão Emissor" v-model="orgaoEmissor" required />
                </div>
                <div class="form-group mb-30 col-12 col-md-4">
                  <label for="data-emissao"><i class="fas fa-calendar-alt"></i></label>
                  <input type="text" id="data-emissao" placeholder="Data emissão" v-model="dataEmissor" v-mask="'##/##/####'" required />
                </div>

                <div class="col-12">
                  <p class="title"><i class="fas fa-map-marker-alt"></i> Endereço</p>
                  <hr />
                </div>
                <div class="form-group mb-30 col-12 col-md-4">
                  <label for="form-cep"><i class="fas fa-location-arrow"></i></label>
                  <input type="text" id="form-cep" placeholder="cep" v-model="endereco.cep" required v-mask="'#####-###'" v-on:change="searchCep" />
                  <span class="pass-type" v-if="getLoadingCep"><i class="fa fa-spin fa-spinner"></i></span>
                </div>

                <div class="form-group mb-30 col-12 col-md-8">
                  <label for="form-logradouro"><i class="fas fa-road"></i></label>
                  <input type="text" id="form-logradouro" placeholder="Logradouro" v-model="endereco.logradouro" required />
                </div>
                <div class="form-group mb-30 col-12 col-md-4">
                  <label for="form-numero"><i class="fas fa-map-pin"></i></label>
                  <input type="text" id="form-numero" placeholder="Número" v-model="endereco.numero" required />
                </div>

                <div class="form-group mb-30 col-12 col-md-8">
                  <label for="form-complemento"><i class="fas fa-map-signs"></i></label>
                  <input type="text" id="form-complemento" placeholder="Complemento" v-model="endereco.complemento" />
                </div>

                <div class="form-group mb-30 col-12 col-md-4">
                  <label for="form-bairro"><i class="fas fa-building"></i></label>
                  <input type="text" id="form-bairro" placeholder="Bairro" v-model="endereco.bairro" required />
                </div>

                <div class="form-group mb-30 col-12 col-md-5">
                  <label for="form-cidade"><i class="fas fa-city"></i></label>
                  <input type="text" id="form-cidade" placeholder="Cidade" v-model="endereco.cidade" required />
                </div>

                <div class="form-group mb-30 col-12 col-md-3">
                  <label for="form-estado"><i class="fas fa-flag"></i></label>
                  <!-- <input
                    type="text"
                    id="form-estado"
                    placeholder="Estado"
                    v-model="endereco.estado"
                    required
                  /> -->

                  <select id="form-estado" v-model="endereco.estado" required>
                    <option value="">Estado</option>
                    <option value="AC">AC</option>
                    <option value="AL">AL</option>
                    <option value="AP">AP</option>
                    <option value="AM">AM</option>
                    <option value="BA">BA</option>
                    <option value="CE">CE</option>
                    <option value="DF">DF</option>
                    <option value="ES">ES</option>
                    <option value="GO">GO</option>
                    <option value="MA">MA</option>
                    <option value="MT">MT</option>
                    <option value="MS">MS</option>
                    <option value="MG">MG</option>
                    <option value="PR">PR</option>
                    <option value="PB">PB</option>
                    <option value="PA">PA</option>
                    <option value="PE">PE</option>
                    <option value="PI">PI</option>
                    <option value="RJ">RJ</option>
                    <option value="RN">RN</option>
                    <option value="RS">RS</option>
                    <option value="RO">RO</option>
                    <option value="RR">RR</option>
                    <option value="SC">SC</option>
                    <option value="SE">SE</option>
                    <option value="SP">SP</option>
                    <option value="TO">TO</option>
                  </select>
                </div>

                <div class="col-12" v-if="!identityId">
                  <p class="title"><i class="far fa-user-circle"></i> Conta</p>
                  <hr />
                </div>

                <div class="form-group mb-30 col-12 is-invalid" v-if="!identityId">
                  <label for="login-email"><i class="fas fa-envelope"></i></label>
                  <input type="email" id="login-email" placeholder="Email" v-model="email" required />
                </div>
                <div class="form-group mb-30 col-12 is-invalid" v-if="!identityId">
                  <label for="login-email-conf"><i class="fas fa-envelope"></i></label>
                  <input type="email" id="login-email-conf" placeholder="Confirmação de Email" v-model="emailconf" required />
                </div>
                <div class="form-group mb-30 col-12" v-if="!identityId">
                  <label for="login-pass"><i class="fas fa-lock"></i></label>
                  <input :type="passwordFieldType" id="login-pass" placeholder="Senha" v-model="senha" required />
                  <span class="pass-type" @click="switchVisibility()"
                    ><i class="fas" :class="{ 'fa-eye': passwordFieldType == 'password', 'fa-eye-slash': passwordFieldType != 'password' }"></i
                  ></span>
                </div>
                <div class="form-group mb-30 col-12" v-if="!identityId">
                  <label for="login-pass-conf"><i class="fas fa-lock"></i></label>
                  <input :type="passwordFieldType2" id="login-pass-conf" placeholder="Confirmaçao de senha" v-model="confsenha" required />
                  <span class="pass-type" @click="switchVisibility2()"
                    ><i class="fas" :class="{ 'fa-eye': passwordFieldType2 == 'password', 'fa-eye-slash': passwordFieldType2 != 'password' }"></i
                  ></span>
                </div>
                <div class="form-group checkgroup mb-30 col-12">
                  <input type="checkbox" name="terms" id="check" v-model="aceiteTermo" />
                  <label
                    >&nbsp; Li e Aceito os termos de uso.
                    <!-- <a href="/termos-de-uso" target="_blank"
                      >Clique Aqui</a
                    > -->
                    <a :href="'/pagina/' + getTermoUso.rota" v-if="getTermoUso" target="_blank"> Clique Aqui </a>
                  </label>
                </div>
                <div class="mb-30 col-12">
                  <div class="alert alert-danger" v-for="error in getCadastroError" :key="error">
                    {{ error }}
                  </div>
                  <div class="alert alert-danger" v-if="error">
                    {{ error }}
                  </div>
                </div>
                <div class="form-group mb-0">
                  <button type="submit" class="custom-button login-button" :disabled="getCadastroLoading">
                    <i class="fa fa-spin fa-spinner" v-if="getCadastroLoading"></i>
                    CADASTRAR
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div class="right-side cl-white">
            <div class="section-header mb-0">
              <h3 class="title mt-0">JÁ POSSUI UMA CONTA?</h3>
              <a href="/login" class="custom-button cadastre-button">Entrar</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { APP_URL } from '../../config/config';
import estados from '../../data/estados_brasil.json';
import moment from 'moment';
import notification from '@/mixins/notification';
moment.locale('pt-br');

export default {
  data() {
    return {
      identityId: '',
      email: '',
      emailconf: '',
      senha: '',
      confsenha: '',
      nomeCompleto: '',
      dataNascimento: '',
      dataEmissor: '',
      orgaoEmissor: '',
      genero: '',
      tipoPessoa: 'PF',
      numeroDocumento: '',
      rg: '',
      telefone: '',
      telefoneconf: '',
      provedor: '',
      errors: [],
      estados: estados,
      endereco: {
        cep: '',
        logradouro: '',
        numero: '',
        complemento: '',
        bairro: '',
        cidade: '',
        estado: '',
      },
      aceiteTermo: false,
      error: '',
      colorPrimary: process.env.VUE_APP_PRIMARY_COLOR,
      colorSecondary: process.env.VUE_APP_SECONDARY_COLOR,
      colorConstrast: process.env.VUE_APP_CONSTRAST_COLOR,
      passwordFieldType: 'password',
      passwordFieldType2: 'password',
    };
  },
  mixins: [notification],
  computed: {
    ...mapGetters(['getCadastroLoading', 'getCadastroError', 'getRegisterCompleted', 'getAuth', 'getLoadingCep', 'getPaginas']),
    getTermoUso() {
      if (!this.getPaginas) return;
      return this.getPaginas.find((x) => x.rota == 'termos-de-uso');
    },
  },
  methods: {
    ...mapActions(['setCadastro', 'loadCep']),
    switchVisibility() {
      this.passwordFieldType = this.passwordFieldType === 'password' ? 'text' : 'password';
    },
    switchVisibility2() {
      this.passwordFieldType2 = this.passwordFieldType2 === 'password' ? 'text' : 'password';
    },
    async cadastro() {
      this.error = '';

      if (this.telefone !== this.telefoneconf) {
        this.error = 'Os campos Telefone e Confirmação estão diferentes';
        return false;
      }

      if (this.email.toLowerCase() !== this.emailconf.toLowerCase()) {
        this.error = 'Os campos Email e Confirmação de Email estão diferentes.';
        return false;
      }

      if (this.senha !== this.confsenha) {
        this.error = 'Os campos Senha e Confirmação de Senha estão diferentes.';
        return false;
      }

      if (this.dataNascimento.length === 10 && !moment(this.dataNascimento, 'DD/MM/YYYY').isValid()) {
        this.error = 'A Data de Nascimento está inválida.';
        return false;
      }

      if (this.dataNascimento.length === 10 && !moment(this.dataEmissor, 'DD/MM/YYYY').isValid()) {
        this.error = 'A Data Emissão está inválida.';
        return false;
      }

      if (!this.aceiteTermo) {
        this.error = 'Leia e aceite os termos de uso.';
        return false;
      }

      const payload = {
        identityId: this.identityId,
        nomeCompleto: this.nomeCompleto,
        dataNascimento: this.dataNascimento && this.dataNascimento.split('/').reverse().join('-'),
        dataEmissao: this.dataEmissor && this.dataEmissor.split('/').reverse().join('-'),
        orgaoEmissor: this.orgaoEmissor,
        genero: this.genero,
        tipoPessoa: this.tipoPessoa,
        numeroDocumento: this.numeroDocumento,
        telefoneCelular: this.telefone,
        rg: this.rg,
        email: this.email.toLowerCase(),
        senha: this.senha,
        endereco: this.endereco,
        returnUrl: APP_URL + 'login?emailConfirmado',
      };
      try {
        await this.setCadastro(payload);
        this.showToast({ title: 'Cadastro completo' });
        this.$router.push({ name: 'index' });
      } catch (_) {
        this.showAlert({
          type: 'error',
          title: 'ATENÇÃO',
          message: 'Houve um erro ao completar o cadastro',
        });
      }
    },
    searchCep(event) {
      if (event) {
        event.preventDefault();
      }

      if (this.endereco.cep.length === 9) {
        this.loadCep(this.endereco.cep)
          .then((resp) => {
            this.endereco.logradouro = resp.logradouro;
            this.endereco.bairro = resp.bairro;
            this.endereco.cidade = resp.localidade;
            this.endereco.estado = resp.uf;
          })
          .catch(() => {
            this.endereco.logradouro = '';
            this.endereco.bairro = '';
            this.endereco.cidade = '';
            this.endereco.estado = '';
          });
      }
    },
  },
  mounted() {
    this.email = this.getAuth && this.getAuth?.profile ? this.getAuth?.profile?.email : this.getAuth?.email;
    this.emailconf = this.getAuth && this.getAuth?.profile ? this.getAuth?.profile?.email : this.getAuth?.email;
    this.nomeCompleto = this.getAuth && this.getAuth?.profile ? this.getAuth?.profile?.full_name : this.getAuth?.full_name;
    this.identityId = this.getAuth && this.getAuth?.profile ? this.getAuth?.profile?.uid : this.getAuth?.uid;
  },
};
</script>

<style scoped>
.account-wrapper .right-side,
.login-button {
  background-color: v-bind(colorPrimary) !important;
  color: v-bind(colorConstrast) !important;
  font-weight: 500 !important;
}

.cadastre-button {
  background-color: v-bind(colorConstrast);
  color: v-bind(colorPrimary);
}
.login-form .form-group input,
select {
  padding-left: 40px;
}

.red {
  color: rgb(146, 0, 0);
  font-size: 18px;
}
@media (max-width: 576px) {
  .account-section {
    padding-top: 180px;
  }
}
</style>
