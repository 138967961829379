<template>
  <!--============= Banner Section Starts Here =============-->
  <section class="banner-section-4 bg_img oh">
    <div class="banner-shape d-none d-lg-block bot-0"></div>
    <div class="container-fluid p-0">
      <bannerSlide />
    </div>
  </section>
  <!--============= Banner Section Ends Here =============-->
</template>

<script>
import BannerSlide from './BannerSlide.vue';
export default {
  components: {
    BannerSlide,
  },
};
</script>

<style>
.header-bottom.active {
  background: #fff;
}
</style>
