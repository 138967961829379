<template>
  <div>
    <div class="overlayer" id="overlayer">
      <div class="loader">
        <div class="loader-inner"></div>
      </div>
    </div>
    <a href="javascript:void(0)" class="scrollToTop"
      ><i class="fas fa-angle-up"></i
    ></a>
    <div class="overlay"></div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
