var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"container-fluid banner"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-md-center"},[(_vm.getLeilao)?_c('div',{staticClass:"banner-leilao col-lg-3"},[_c('div',{staticClass:"auction-item-2"},[_c('div',{staticClass:"auction-thumb border-radius-5 thumb-height-247"},[_c('img',{attrs:{"src":_vm.getLeilao.foto.url,"title":_vm.getLeilao.nome}})]),_c('div',{staticClass:"field-badge"},[(_vm.getLeilao.tipo == 'JUDICIAL')?_c('span',{staticClass:"badge badge-gray bg-secondary w-100"},[_vm._v("JUDICIAL")]):_vm._e(),(_vm.getLeilao.tipo == 'EXTRAJUDICIAL')?_c('span',{staticClass:"badge badge-yellow bg-secondary w-100"},[_vm._v("EXTRAJUDICIAL")]):_vm._e(),(_vm.getLeilao.tipo == 'VENDA DIRETA')?_c('span',{staticClass:"badge badge-orange bg-secondary w-100"},[_vm._v("VENDA DIRETA")]):_vm._e()]),_c('div',{staticClass:"auction-content"},[_c('div',{staticClass:"title-adress text-center"},[_c('h6',{staticClass:"title mb-1 px-2 w-100 text-truncate"},[_vm._v(" "+_vm._s(_vm.getLeilao.nome)+" ")]),_c('p',{staticClass:"titulo-leilao"},[_vm._v(_vm._s(_vm.getLeilao.titulo))])])])])]):_vm._e(),(_vm.getLeilao)?_c('div',{staticClass:"banner-info col-lg-6"},[_c('div',{staticClass:"info-banner"},[_c('h3',{staticClass:"pt-4 pb-2"},[_vm._v(_vm._s(_vm.getLeilao.nome))]),(_vm.getLeilao.judicial)?_c('div',_vm._l((_vm.getLeilao.pracas),function(praca){return _c('p',{key:praca.pracaLeilaoId,staticClass:"titulo-descricao my-1",class:{ 'leilao-fechado': praca.status == 2 }},[_c('strong',[_vm._v(_vm._s(praca.numeroPraca)+"ª LEILÃO ")]),_vm._v(" "+_vm._s(_vm._f("formatDataHora")(praca.dataExecucao))+" ")])}),0):_c('p',{staticClass:"titulo-descricao my-1"},[_c('strong',[_vm._v("DATA E HORA: ")]),_vm._v(" "+_vm._s(_vm._f("formatDataHora")(_vm.getLeilao.dataLeilao))+" ")]),_c('p',{staticClass:"titulo-descricao my-1"},[_c('strong',[_vm._v("STATUS: ")]),(
                  _vm.getLeilao.statusSite === 'aberto' &&
                  (_vm.getLeilao.status === 'Aberto' || _vm.getLeilao.status === 'Agendado') &&
                  _vm.getLeilao.tipo != 'VENDA DIRETA'
                )?_c('span',[_vm._v("ABERTO PARA LANCES")]):(
                  _vm.getLeilao.statusSite === 'aberto' &&
                  (_vm.getLeilao.status === 'Aberto' || _vm.getLeilao.status === 'Agendado') &&
                  _vm.getLeilao.tipo == 'VENDA DIRETA'
                )?_c('span',[_vm._v("ABERTO")]):(_vm.getLeilao.statusSite === 'aberto' && (_vm.getLeilao.status === 'Em Andamento' || _vm.getLeilao.status === 'Em Pregão'))?_c('span',[_vm._v("EM PREGÃO"),_c('span',{staticClass:"ball-pregao box-pulse-yellow"})]):(_vm.getLeilao.statusSite === 'fechado' && _vm.getLeilao.status === 'Cancelado')?_c('span',[_vm._v("CANCELADO")]):(_vm.getLeilao.statusSite === 'fechado' || _vm.getLeilao.status === 'Encerrado')?_c('span',[_vm._v("ENCERRADO")]):_c('span',[_vm._v("EM BREVE")])]),(_vm.getLeilao.nomeLeiloeiro)?_c('p',{staticClass:"titulo-descricao my-1"},[_c('strong',[_vm._v("LEILOEIRO OFICIAL: ")]),_vm._v(" "+_vm._s(_vm.getLeilao.nomeLeiloeiro)+" ")]):_vm._e(),(!_vm.getLeilao.judicial)?_c('p',{staticClass:"titulo-descricao my-1"},[_c('strong',[_vm._v("COMITENTE: ")]),_vm._v(" "+_vm._s(_vm.getLeilao.nomeComitente))]):_vm._e()]),(_vm.getLeilao.anexos.length > 0)?_c('div',{staticClass:"buttons mt-3"},[_c('ul',{staticClass:"lista-botoes"},_vm._l((_vm.getLeilao.anexos),function(anexo){return _c('li',{key:anexo.ArquivoId},[_c('a',{staticClass:"edital-button",attrs:{"href":anexo.arquivo.url,"target":"_blank"}},[_vm._v(_vm._s(anexo.nome))])])}),0)]):_vm._e()]):_vm._e(),(_vm.getLeilao.regrasHabilitacao && _vm.getLeilao.regrasHabilitacao.length > 0 && _vm.getLeilao.statusSite === 'aberto')?_c('div',{staticClass:"col-lg-3 text-center d-flex align-items-center justify-content-center"},[(
              (!_vm.getAuth || (_vm.getAuth && !_vm.getLeilaoHabilitado.habilitado && _vm.getLeilaoHabilitado.status != 'Pendente')) &&
              !_vm.getLeilao.habilitacaoDesativada
            )?_c('a',{staticClass:"custom-button leilao-button",attrs:{"href":"javascript:void(0)","data-target":"#modalCondicoes","data-toggle":"modal"},on:{"click":function($event){return _vm.$emit('abreModalHabilitar')}}},[_vm._v(" HABILITE-SE ")]):_vm._e(),(
              (!_vm.getAuth || (_vm.getAuth && !_vm.getLeilaoHabilitado.habilitado && _vm.getLeilaoHabilitado.status != 'Pendente')) &&
              _vm.getLeilao.habilitacaoDesativada
            )?_c('a',{staticClass:"custom-button leilao-button",attrs:{"href":"javascript:void(0)","data-target":"#modalHabilitacaoEncerrada","data-toggle":"modal"}},[_vm._v(" HABILITE-SE ")]):_vm._e(),(_vm.getAuth && _vm.getLeilaoHabilitado.status == 'Pendente')?_c('a',{staticClass:"custom-button btn-yellow text-white"},[_vm._v(" HABILITAÇÃO EM ANÁLISE ")]):_vm._e(),(_vm.getAuth && _vm.getLeilaoHabilitado.habilitado && _vm.getLeilao.tipo != 'VENDA DIRETA')?_c('a',{staticClass:"custom-button leilao-button text-white",on:{"click":_vm.openPainel}},[_vm._v(" AUDITÓRIO VIRTUAL ")]):_vm._e()]):_vm._e()])])]),(_vm.getLeilao.observacao)?_c('div',{staticClass:"container-fluid descricao"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row pt-3"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"infos d-flex"},[_c('p',{staticClass:"pb-1 col-12",domProps:{"innerHTML":_vm._s(_vm.getLeilao.observacao)}})])])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }