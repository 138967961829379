import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { API_URL } from '../config/config';

export default {
  // called by Vue.use(FirstPlugin)
  async install(Vue) {
    if (navigator && navigator.locks && navigator.locks.request) {
      const promise = new Promise((res) => {
        return res;
      });

      navigator.locks.request('lock_signalr', { mode: 'shared' }, () => {
        return promise;
      });
    }

    const connection = new HubConnectionBuilder()
      .withUrl(`${API_URL}leilaohub`)
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (retryContext) => {
          if (retryContext.elapsedMilliseconds < 60000) {
            // If we've been reconnecting for less than 60 seconds so far,
            // wait between 0 and 10 seconds before the next reconnect attempt.
            return Math.random() * 10000;
          } else {
            // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
            return null;
          }
        },
      })
      .configureLogging(LogLevel.Information)
      .build();

    let startedPromise = null;
    function start() {
      startedPromise = connection.start().catch(() => {
        return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000));
      });
      return startedPromise;
    }
    connection.onclose(() => start());
    connection.onreconnected(() => start());

    start();
    //Vue.prototype.$signalR = connection

    const leilaoHub = new Vue();
    // every component will use this.$questionHub to access the event bus
    Vue.prototype.$signalR = leilaoHub;
    // Forward server side SignalR events through $questionHub, where components will listen to them
    connection.on('NewLance', (loteId) => {
      leilaoHub.$emit('new-lance', loteId);
    });

    leilaoHub.joinLeilao = (leilaoId) => {
      return startedPromise.then(() => connection.invoke('JoinLeilao', leilaoId)).catch(console.error);
    };
  },
};
